/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CampoConfigurabile } from './campoConfigurabile';
import { Corso } from './corso';
import { PlatformUser } from './platformUser';

export interface CicloDottorato { 
    id?: string;
    updatedAt?: Date;
    numCiclo?: number;
    annoInizio?: number;
    annoFine?: number;
    titolo?: string;
    coordinatore?: PlatformUser;
    coordinatorePta?: PlatformUser;
    budgetPta?: Array<PlatformUser>;
    gruppoCoordinamento?: Array<PlatformUser>;
    stato?: CicloDottorato.StatoEnum;
    campoConfigurabileAnagraficaStudente?: CampoConfigurabile;
    campoConfigurabilePianoStudi?: CampoConfigurabile;
    periodoEsterno?: CampoConfigurabile;
    tipologiaCurricula?: CampoConfigurabile;
    tipologiaBorsa?: CampoConfigurabile;
    tipologiaSsd?: CampoConfigurabile;
    iscrizioniAperte?: boolean;
    offertaFormativa?: Array<Corso>;
    collegioDocenti?: Array<PlatformUser>;
}
export namespace CicloDottorato {
    export type StatoEnum = 'BOZZA' | 'ELIMINATO' | 'ATTIVO' | 'COMPLETATO';
    export const StatoEnum = {
        BOZZA: 'BOZZA' as StatoEnum,
        ELIMINATO: 'ELIMINATO' as StatoEnum,
        ATTIVO: 'ATTIVO' as StatoEnum,
        COMPLETATO: 'COMPLETATO' as StatoEnum
    };
}