import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {merge} from 'lodash-es';
import {FuseConfirmationDialogComponent} from '@fuse/services/confirmation/dialog/dialog.component';
import {FuseConfirmationConfig, SnackbarConfig} from '@fuse/services/confirmation/confirmation.types';
import {SnackbarComponent, SnackbarTypes} from "./snackbar/snackbar.component";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class FuseConfirmationService {
    private _defaultConfig: FuseConfirmationConfig = {
        title: '',
        message: '',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation',
            color: 'warn'
        },
        onBackdrop: {
            show: true,
            backdrop: false,
        },
    };

    private _defaultSnackbarConfig: SnackbarConfig = {
        message: '',
        type: SnackbarTypes.Success,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000
    };

    loadingSpinner: boolean = false;


    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog,
        public snackBar: MatSnackBar
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !!userConfig?.onBackdrop?.function || userConfig?.onBackdrop?.backdrop,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }


    openSnackBar(_config: SnackbarConfig) {
        const userConfig = merge({}, this._defaultSnackbarConfig, _config);
        const config: MatSnackBarConfig = new MatSnackBarConfig();
        config.verticalPosition = userConfig.verticalPosition
        config.horizontalPosition = userConfig.horizontalPosition
        config.duration = userConfig.duration
        config.data = {message: userConfig.message, type: userConfig.type};
        config.panelClass = this.getPanelClass(userConfig.type);
        this.snackBar.openFromComponent(SnackbarComponent, config);
    }

    openSnackBarGenericError(_translocoService: TranslocoService, err?: HttpErrorResponse){
        const activeLang = _translocoService.getActiveLang()
        const translation = _translocoService.getTranslation().get(activeLang)
        const message = get(translation, 'common.not_loading_data', null)
        this.openSnackBar({
            message: err?.error?.message || message,
            type: SnackbarTypes.Error
        })
    }

    getPanelClass(type: SnackbarTypes) {
        switch (type) {
            case SnackbarTypes.Success:
                return 'mat-bg-success';
            case SnackbarTypes.Error:
                return 'mat-bg-error';
            case SnackbarTypes.Warning:
                return 'mat-bg-warn';
            default:
                return 'error';
        }
    }

    showLoader(){
        this.loadingSpinner = true;
    }

    hideLoader(){
        this.loadingSpinner = false;
    }

    errorGetData(_translocoService: TranslocoService, goToHome: () => void, getData: () => void) {
        const activeLang = _translocoService.getActiveLang()
        const translation = _translocoService.getTranslation().get(activeLang)
        this.open({
            message: get(translation, 'common.not_loading_data', null),
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    function: () => goToHome(),
                    label: get(translation, 'common.go_to_home', null),
                    color: 'accent'
                },
                {
                    function: () => getData(),
                    label: get(translation, 'common.try_again', null),
                    color: 'primary'
                }
            ]
        })
    }


}
