/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di pagine di visualizzazione. Valori previsti: *ANAGRAFICA_STUDENTE*, *PIANO_STUDI*
 */
export type ShownPageType = 'ANAGRAFICA_STUDENTE' | 'PIANO_STUDI';

export const ShownPageType = {
    ANAGRAFICASTUDENTE: 'ANAGRAFICA_STUDENTE' as ShownPageType,
    PIANOSTUDI: 'PIANO_STUDI' as ShownPageType
};