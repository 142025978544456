<ng-container *transloco="let t">
    <app-dialog-wrap [data]="data">
        <mat-dialog-content>
            <app-testo-privacy
                *ngIf="data.isPrivacy; else TerminiECondizioni"></app-testo-privacy>
            <ng-template #TerminiECondizioni>
                <app-testo-termini-e-condizioni></app-testo-termini-e-condizioni>
            </ng-template>

        </mat-dialog-content>


    </app-dialog-wrap>

    <div class="w-full pb-8 pr-6">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5">
            <button [color]="'primary'" mat-raised-button (click)="closeDialog()">{{t('common.close')}}</button>
        </div>
    </div>
</ng-container>
