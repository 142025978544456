/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AttivitaGiornaliera } from '../model/attivitaGiornaliera';
import { AttivitaGiornalieraDTO } from '../model/attivitaGiornalieraDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { RegistroAttivitaFilterForm } from '../model/registroAttivitaFilterForm';
import { RegistroAttivitaStatsDTO } from '../model/registroAttivitaStatsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RegistroAttivitaService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge una voce al Registro Attività di uno studente iscritto ad un ciclo di dottorato.
     * 
     * @param body Voce di spesa da aggiungere.
     * @param user_id Identificativo utente del quale si desidera modificare il Registro Attività. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaGiornaliera>>;
    public addAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaGiornaliera>>>;
    public addAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaGiornaliera>>>;
    public addAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAttivitaInRegistro.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling addAttivitaInRegistro.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AttivitaGiornaliera>>('post',`${this.basePath}/api/v1/registro-attivita/${encodeURIComponent(String(user_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le voci di Registro Attività di uno studente iscritto ad un ciclo di dottorato.
     * 
     * @param body Filtri per il Registro Attività.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistroAttivita(body: RegistroAttivitaFilterForm, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaGiornaliera>>;
    public getRegistroAttivita(body: RegistroAttivitaFilterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaGiornaliera>>>;
    public getRegistroAttivita(body: RegistroAttivitaFilterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaGiornaliera>>>;
    public getRegistroAttivita(body: RegistroAttivitaFilterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getRegistroAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AttivitaGiornaliera>>('post',`${this.basePath}/api/v1/registro-attivita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche del Registro Attività di uno studente iscritto ad un ciclo di dottorato.
     * 
     * @param user_id Identificativo utente del quale si desidera calcolare le statistiche del Registro Attività. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistroAttivitaStats(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RegistroAttivitaStatsDTO>>;
    public getRegistroAttivitaStats(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RegistroAttivitaStatsDTO>>>;
    public getRegistroAttivitaStats(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RegistroAttivitaStatsDTO>>>;
    public getRegistroAttivitaStats(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getRegistroAttivitaStats.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RegistroAttivitaStatsDTO>>('get',`${this.basePath}/api/v1/registro-attivita/${encodeURIComponent(String(user_id))}/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove una voce dal Registro Attività di uno studente iscritto ad un ciclo di dottorato.
     * 
     * @param user_id Identificativo utente del quale si desidera modificare il Registro Attività. Non può essere nullo o vuoto.
     * @param activity_id Identificativo della voce di Registro Attività da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAttivitaInRegistro(user_id: string, activity_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaGiornaliera>>;
    public removeAttivitaInRegistro(user_id: string, activity_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaGiornaliera>>>;
    public removeAttivitaInRegistro(user_id: string, activity_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaGiornaliera>>>;
    public removeAttivitaInRegistro(user_id: string, activity_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling removeAttivitaInRegistro.');
        }

        if (activity_id === null || activity_id === undefined) {
            throw new Error('Required parameter activity_id was null or undefined when calling removeAttivitaInRegistro.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AttivitaGiornaliera>>('delete',`${this.basePath}/api/v1/registro-attivita/${encodeURIComponent(String(user_id))}/${encodeURIComponent(String(activity_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna una voce dal Registro Attività di uno studente iscritto ad un ciclo di dottorato.
     * 
     * @param body Attività da aggiornare.
     * @param user_id Identificativo utente del quale si desidera modificare il Registro Attività. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaGiornaliera>>;
    public updateAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaGiornaliera>>>;
    public updateAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaGiornaliera>>>;
    public updateAttivitaInRegistro(body: AttivitaGiornalieraDTO, user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAttivitaInRegistro.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling updateAttivitaInRegistro.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AttivitaGiornaliera>>('put',`${this.basePath}/api/v1/registro-attivita/${encodeURIComponent(String(user_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
