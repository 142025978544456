import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnagraficaStudenteService } from './api/anagraficaStudente.service';
import { AuthService } from './api/auth.service';
import { BudgetStudenteService } from './api/budgetStudente.service';
import { CampiConfigurabiliService } from './api/campiConfigurabili.service';
import { CicliDottoratoService } from './api/cicliDottorato.service';
import { DateTimeService } from './api/dateTime.service';
import { EmailSenderService } from './api/emailSender.service';
import { ExportService } from './api/export.service';
import { ImportService } from './api/import.service';
import { MediaService } from './api/media.service';
import { OfferteFormativeService } from './api/offerteFormative.service';
import { PianiStudiService } from './api/pianiStudi.service';
import { RegistroAttivitaService } from './api/registroAttivita.service';
import { TesiStudenteService } from './api/tesiStudente.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AnagraficaStudenteService,
    AuthService,
    BudgetStudenteService,
    CampiConfigurabiliService,
    CicliDottoratoService,
    DateTimeService,
    EmailSenderService,
    ExportService,
    ImportService,
    MediaService,
    OfferteFormativeService,
    PianiStudiService,
    RegistroAttivitaService,
    TesiStudenteService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
