/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di permessi di modifica in piattaforma. Valori previsti: *ADMIN*, *DOCENTE*, *STUDENTE*, *COORDINATORE*, *COORDINATORE_PTA*, *BUDGET_PTA*, *MEMBRO_COORDINAMENTO*, *MEMBRO_COLLEGIO*, *SUPERVISORE*, *CO_SUPERVISORE*, *REVISORE*
 */
export type PermissionType = 'ADMIN' | 'DOCENTE' | 'STUDENTE' | 'PERSONALE_PTA' | 'COORDINATORE' | 'COORDINATORE_PTA' | 'BUDGET_PTA' | 'MEMBRO_COORDINAMENTO' | 'MEMBRO_COLLEGIO' | 'SUPERVISORE' | 'CO_SUPERVISORE' | 'REVISORE';

export const PermissionType = {
    ADMIN: 'ADMIN' as PermissionType,
    DOCENTE: 'DOCENTE' as PermissionType,
    STUDENTE: 'STUDENTE' as PermissionType,
    PERSONALEPTA: 'PERSONALE_PTA' as PermissionType,
    COORDINATORE: 'COORDINATORE' as PermissionType,
    COORDINATOREPTA: 'COORDINATORE_PTA' as PermissionType,
    BUDGETPTA: 'BUDGET_PTA' as PermissionType,
    MEMBROCOORDINAMENTO: 'MEMBRO_COORDINAMENTO' as PermissionType,
    MEMBROCOLLEGIO: 'MEMBRO_COLLEGIO' as PermissionType,
    SUPERVISORE: 'SUPERVISORE' as PermissionType,
    COSUPERVISORE: 'CO_SUPERVISORE' as PermissionType,
    REVISORE: 'REVISORE' as PermissionType
};