import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';

import {LocalStorageService} from "../../../shared/services/local-storage.service";
import {PathEnum} from "../../../app.routing";

@Injectable({
    providedIn: 'root'
})
export class UserRoleGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router,
                private localStorageService: LocalStorageService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verificaRuolo(route);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verificaRuolo(childRoute);
    }

    verificaRuolo(route: ActivatedRouteSnapshot) {
        const roles = route.data['ruoliAutorizzati'];
        const ruoli = this.localStorageService.getRules();
        if (ruoli.length > 0 && ruoli.some(ruolo => roles.includes(ruolo))) {
            return true;
        } else {
            this.router.navigate([PathEnum.HOME]);
            return false;
        }
    }
}
