export const versionInfo = (() => {
  try {

    // @ts-ignore
    return require('./git-version.json');
  } catch {
    // In dev the file might not exist:
    return { tag: 'local', hash: 'dev' };
  }
})();
