/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di sottocampi supportati. Valori previsti: *URL_FOTO*, *REL_ANNUALI_ALLEGATO_1*, *REL_ANNUALI_ALLEGATO_2*, *REL_ANNUALI_ALLEGATO_3*, *REL_ANNUALI_ALLEGATO_7*, *REG_ANNUALI_ALLEGATO_4*, *REG_ANNUALI_ALLEGATO_5*, *REG_ANNUALI_ALLEGATO_6*, *REG_ANNUALI_ALLEGATO_8*, *PIANO_STUDI_ALLEGATO_1*, *PIANO_STUDI_ALLEGATO_2*, *PIANO_STUDI_ALLEGATO_3*, *PIANO_STUDI_ALLEGATO_4*, *SCHEDA_TESI_RELAZIONE_FINALE*, *SCHEDA_TESI_ABSTRACT_INGLESE*, *SCHEDA_TESI_TESI_SOTTOMESSA*, *SCHEDA_TESI_TESI_DEFINITIVA*, *SCHEDA_TESI_REVISORE_UNO*, *SCHEDA_TESI_REVISORE_DUE*, *SCHEDA_TESI_ACCORDO_CO_TUTELA*
 */
export type AttachmentTargetType = 'ALLEGATO_DELIBERA' | 'URL_FOTO' | 'REL_ANNUALI_ALLEGATO_1' | 'REL_ANNUALI_ALLEGATO_2' | 'REL_ANNUALI_ALLEGATO_3' | 'REL_ANNUALI_ALLEGATO_7' | 'REG_ANNUALI_ALLEGATO_4' | 'REG_ANNUALI_ALLEGATO_5' | 'REG_ANNUALI_ALLEGATO_6' | 'REG_ANNUALI_ALLEGATO_8' | 'PIANO_STUDI_ALLEGATO_1' | 'PIANO_STUDI_ALLEGATO_2' | 'PIANO_STUDI_ALLEGATO_3' | 'PIANO_STUDI_ALLEGATO_4' | 'SCHEDA_TESI_RELAZIONE_FINALE' | 'SCHEDA_TESI_ABSTRACT_INGLESE' | 'SCHEDA_TESI_TESI_SOTTOMESSA' | 'SCHEDA_TESI_TESI_DEFINITIVA' | 'SCHEDA_TESI_REVISORE_UNO' | 'SCHEDA_TESI_REVISORE_DUE' | 'SCHEDA_TESI_ACCORDO_CO_TUTELA' | 'SCHEDA_TESI_CERTIFICATO_ANTIPLAGIO';

export const AttachmentTargetType = {
    ALLEGATODELIBERA: 'ALLEGATO_DELIBERA' as AttachmentTargetType,
    URLFOTO: 'URL_FOTO' as AttachmentTargetType,
    RELANNUALIALLEGATO1: 'REL_ANNUALI_ALLEGATO_1' as AttachmentTargetType,
    RELANNUALIALLEGATO2: 'REL_ANNUALI_ALLEGATO_2' as AttachmentTargetType,
    RELANNUALIALLEGATO3: 'REL_ANNUALI_ALLEGATO_3' as AttachmentTargetType,
    RELANNUALIALLEGATO7: 'REL_ANNUALI_ALLEGATO_7' as AttachmentTargetType,
    REGANNUALIALLEGATO4: 'REG_ANNUALI_ALLEGATO_4' as AttachmentTargetType,
    REGANNUALIALLEGATO5: 'REG_ANNUALI_ALLEGATO_5' as AttachmentTargetType,
    REGANNUALIALLEGATO6: 'REG_ANNUALI_ALLEGATO_6' as AttachmentTargetType,
    REGANNUALIALLEGATO8: 'REG_ANNUALI_ALLEGATO_8' as AttachmentTargetType,
    PIANOSTUDIALLEGATO1: 'PIANO_STUDI_ALLEGATO_1' as AttachmentTargetType,
    PIANOSTUDIALLEGATO2: 'PIANO_STUDI_ALLEGATO_2' as AttachmentTargetType,
    PIANOSTUDIALLEGATO3: 'PIANO_STUDI_ALLEGATO_3' as AttachmentTargetType,
    PIANOSTUDIALLEGATO4: 'PIANO_STUDI_ALLEGATO_4' as AttachmentTargetType,
    SCHEDATESIRELAZIONEFINALE: 'SCHEDA_TESI_RELAZIONE_FINALE' as AttachmentTargetType,
    SCHEDATESIABSTRACTINGLESE: 'SCHEDA_TESI_ABSTRACT_INGLESE' as AttachmentTargetType,
    SCHEDATESITESISOTTOMESSA: 'SCHEDA_TESI_TESI_SOTTOMESSA' as AttachmentTargetType,
    SCHEDATESITESIDEFINITIVA: 'SCHEDA_TESI_TESI_DEFINITIVA' as AttachmentTargetType,
    SCHEDATESIREVISOREUNO: 'SCHEDA_TESI_REVISORE_UNO' as AttachmentTargetType,
    SCHEDATESIREVISOREDUE: 'SCHEDA_TESI_REVISORE_DUE' as AttachmentTargetType,
    SCHEDATESIACCORDOCOTUTELA: 'SCHEDA_TESI_ACCORDO_CO_TUTELA' as AttachmentTargetType,
    SCHEDATESICERTIFICATOANTIPLAGIO: 'SCHEDA_TESI_CERTIFICATO_ANTIPLAGIO' as AttachmentTargetType
};