/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tentativi di risoluzione per problemi noti avvenuti durante l'esecuzione dell'applicativo. Azioni ammesse:  - *LOCK_USER*: bloccare un utente, disabilitandone l'accesso - *RESEND_VERIFICATION_EMAIL*: avviare una nuova fase di verifica dell'account di un utente - *RESEND_RECOVERY_EMAIL*: avviare una nuova fase di cambio password dell'account di un utente - *RESEND_SET_PASSWORD_EMAIL*: avviare una nuova fase di scelta password dell'account di un utente registrato da admin - *RESEND_ALLOW_CREDENTIALS_EMAIL*: non supportato - *RESEND_ENABLE_ACCOUNT_EMAIL*: non supportato - *RESEND_ENABLED_ACCOUNT_EMAIL*: non supportato - *RESEND_SUBMITTED_STUDY_PLAN_EMAIL*: non supportato - *RESEND_APPROVE_STUDY_PLAN_EMAIL*: non supportato - *RESEND_APPROVED_STUDY_PLAN_EMAIL*: non supportato - *RESEND_REJECTED_STUDY_PLAN_EMAIL*: non supportato - *CONTACT_SUPPORT*: contattare il supporto tecnico
 */
export type PlatformActionType = 'LOCK_USER' | 'RESEND_VERIFICATION_EMAIL' | 'RESEND_RECOVERY_EMAIL' | 'RESEND_SET_PASSWORD_EMAIL' | 'RESEND_ALLOW_CREDENTIALS_EMAIL' | 'RESEND_ENABLE_ACCOUNT_EMAIL' | 'RESEND_ENABLED_ACCOUNT_EMAIL' | 'CONTACT_SUPPORT' | 'RESEND_SUBMITTED_STUDY_PLAN_EMAIL' | 'RESEND_APPROVE_STUDY_PLAN_EMAIL' | 'RESEND_APPROVED_STUDY_PLAN_EMAIL' | 'RESEND_REJECTED_STUDY_PLAN_EMAIL';

export const PlatformActionType = {
    LOCKUSER: 'LOCK_USER' as PlatformActionType,
    RESENDVERIFICATIONEMAIL: 'RESEND_VERIFICATION_EMAIL' as PlatformActionType,
    RESENDRECOVERYEMAIL: 'RESEND_RECOVERY_EMAIL' as PlatformActionType,
    RESENDSETPASSWORDEMAIL: 'RESEND_SET_PASSWORD_EMAIL' as PlatformActionType,
    RESENDALLOWCREDENTIALSEMAIL: 'RESEND_ALLOW_CREDENTIALS_EMAIL' as PlatformActionType,
    RESENDENABLEACCOUNTEMAIL: 'RESEND_ENABLE_ACCOUNT_EMAIL' as PlatformActionType,
    RESENDENABLEDACCOUNTEMAIL: 'RESEND_ENABLED_ACCOUNT_EMAIL' as PlatformActionType,
    CONTACTSUPPORT: 'CONTACT_SUPPORT' as PlatformActionType,
    RESENDSUBMITTEDSTUDYPLANEMAIL: 'RESEND_SUBMITTED_STUDY_PLAN_EMAIL' as PlatformActionType,
    RESENDAPPROVESTUDYPLANEMAIL: 'RESEND_APPROVE_STUDY_PLAN_EMAIL' as PlatformActionType,
    RESENDAPPROVEDSTUDYPLANEMAIL: 'RESEND_APPROVED_STUDY_PLAN_EMAIL' as PlatformActionType,
    RESENDREJECTEDSTUDYPLANEMAIL: 'RESEND_REJECTED_STUDY_PLAN_EMAIL' as PlatformActionType
};