<ng-container *ngIf="!fuseConfirmationService?.loadingSpinner; else loadSpinner">
    <ng-container *transloco="let t ">
        <!-- Button -->
        <button
            mat-icon-button
            [matMenuTriggerFor]="userActions">
    <span class="relative">
              <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">

                      <ng-container>
                             <div
                                 class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{user?.email?.charAt(0) || '?'}}
                            </div>
                      </ng-container>
              </div>
    </span>
        </button>

        <mat-menu
            [xPosition]="'before'"
            #userActions="matMenu">
            <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{t('home.signed_in_as')}}</span>
            <span style="  white-space: nowrap;width: 250px;overflow: hidden;text-overflow: ellipsis;"
                  class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item
                    *ngIf="showProfilo"
                    (click)=goToProfiloUtente()>
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                <span>{{t('home.registry')}}</span>
            </button>

            <mat-divider *ngIf="showProfilo" class="my-2"></mat-divider>

            <button
                mat-menu-item
                (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <span>{{t('common.sign_out')}}</span>
            </button>
        </mat-menu>
    </ng-container>
</ng-container>
<ng-template #loadSpinner>
    <app-spinner></app-spinner>
</ng-template>

