<div class="privacy">
    <div class="corpopagina">
        <div class="viewPar BLOBAlignLeft">
            <p><strong class="userFormat1">Titolare del Trattamento dei Dati</strong></p>
            <p>South Engineering srl, Corso Garibaldi 38, 80021 Afragola (NA)</p>
            <p><strong>Indirizzo email del Titolare:</strong> amministrazione@southengineering.it<br></p>
        </div>
        <strong class="userFormat1">Tipologie di Dati raccolti</strong>
        <ol class="viewOL BLOBListSymbolDecimal">
            <li class="viewLI BLOBAlignLeft">Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o
                tramite terze parti, ci sono: nome; cognome; numero di telefono; email; Dati di utilizzo; Strumenti di
                Tracciamento; risposte alle domande; clic; eventi keypress; eventi relativi ai sensori di movimento;
                movimenti del mouse; posizione relativa allo scorrimento; eventi touch.
            </li>
            <li class="viewLI BLOBAlignLeft">Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle
                sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima
                della raccolta dei dati stessi.
            </li>

            <li class="viewLI BLOBAlignLeft">I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso
                di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.
            </li>
            <li class="viewLI BLOBAlignLeft">Se non diversamente specificato, tutti i Dati richiesti da questa
                Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per
                questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come
                facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna
                conseguenza sulla disponibilità del Servizio o sulla sua operatività.
            </li>
            <li class="viewLI BLOBAlignLeft">Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono
                incoraggiati a contattare il Titolare.
            </li>
            <li class="viewLI BLOBAlignLeft">L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da
                parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non
                diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle
                ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.
            </li>
            <li class="viewLI BLOBAlignLeft">L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti,
                pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o
                diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
            </li>
        </ol>
        <div class="viewLineBreak">&nbsp;</div>


        <div class="viewPar BLOBAlignLeft">
            <p><strong class="userFormat1">Modalità e luogo del trattamento dei Dati raccolti</strong></p>
        </div>
        <strong class="userFormat1">Modalità di trattamento</strong>
        <ol class="viewOL BLOBListSymbolDecimal">
            <li class="viewLI BLOBAlignLeft">Il Titolare adotta le opportune misure di sicurezza volte ad impedire
                l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
            </li>
            <li class="viewLI BLOBAlignLeft">Il trattamento viene effettuato mediante strumenti informatici e/o
                telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate.
                Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
                nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali,
                amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
                postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se
                necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili
                potrà sempre essere richiesto al Titolare del Trattamento.
            </li>

        </ol>

        <strong class="userFormat1">Base giuridica del trattamento</strong>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:
        <ol class="viewOL BLOBListSymbolDecimal">
            <li class="viewLI BLOBAlignLeft">l’Utente ha prestato il consenso per una o più finalità specifiche; Nota:
                in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba
                sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a
                quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il
                trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati
                Personali;
            </li>
            <li class="viewLI BLOBAlignLeft">il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o
                all'esecuzione di misure precontrattuali;
            </li>
            <li class="viewLI BLOBAlignLeft">il trattamento è necessario per adempiere un obbligo legale al quale è
                soggetto il Titolare;
            </li>
            <li class="viewLI BLOBAlignLeft">il trattamento è necessario per l'esecuzione di un compito di interesse
                pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;
            </li>
            <li class="viewLI BLOBAlignLeft">il trattamento è necessario per il perseguimento del legittimo interesse
                del Titolare o di terzi.
            </li>
        </ol>
        <p>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
            trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un
            contratto o necessario per concludere un contratto.</p>


        <strong class="userFormat1">Luogo</strong>
        <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte
            nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare. </p>
        <p>I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si
            trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla
            sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
        <p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di
            fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o
            costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal
            Titolare per proteggere i Dati.</p>
        <p>L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo
            documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare
            contattandolo agli estremi riportati in apertura.</p>
        <div class="viewLineBreak">&nbsp;</div>

        <strong class="userFormat1">Periodo di conservazione</strong>
        <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.</p>
        <ol class="viewOL BLOBListSymbolDecimal">
            <li class="viewLI BLOBAlignLeft">I Dati Personali raccolti per scopi collegati all’esecuzione di un
                contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di
                tale contratto.
            </li>
            <li class="viewLI BLOBAlignLeft">I Dati Personali raccolti per finalità riconducibili all’interesse
                legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può
                ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative
                sezioni di questo documento o contattando il Titolare.
            </li>
        </ol>
        <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a
            lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
            conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di
            un’autorità.</p>
        <p>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale
            termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non
            potranno più essere esercitati.</p>
        <div class="viewLineBreak">&nbsp;</div>


        <div class="viewPar BLOBAlignLeft">
            <p><strong class="userFormat1">Finalità del Trattamento dei Dati raccolti</strong></p>
            <p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli
                obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o
                quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le
                seguenti finalità: Registrazione ed autenticazione fornite direttamente da questa Applicazione,
                Protezione dallo SPAM e Salvataggio e gestione di backup.</p>
            <p>Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per
                ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli sul trattamento dei Dati
                Personali”.</p>
        </div>
        <div class="viewLineBreak">&nbsp;</div>

        <div class="viewPar BLOBAlignLeft">
            <p><strong class="userFormat1">Dettagli sul trattamento dei Dati Personali</strong></p>
            <p>I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
            <div>
                <p><strong>Protezione dallo spam</strong></p>
                <p>Questo tipo di servizi analizza il traffico di questa Applicazione, potenzialmente contenente Dati
                    Personali degli Utenti, al fine di filtrarlo da parti di traffico, messaggi e contenuti riconosciuti
                    come SPAM.</p>

                <p><strong>Google reCAPTCHA (Google Ireland Limited)</strong></p>
                Google reCAPTCHA è un servizio di protezione dallo SPAM fornito da Google Ireland Limited.
                L'utilizzo del sistema reCAPTCHA è soggetto alla privacy policy e ai termini di utilizzo di Google.
                Dati Personali trattati: clic; Dati di utilizzo; eventi keypress; eventi relativi ai sensori di
                movimento; eventi touch; movimenti del mouse; posizione relativa allo scorrimento; risposte alle
                domande; Strumenti di Tracciamento.
                <p>Luogo del trattamento: Irlanda – Privacy Policy.</p>
            </div>
            <div>
                <p><strong>Registrazione ed autenticazione fornite direttamente da questa Applicazione</strong></p>
                <p>Con la registrazione o l’autenticazione l’Utente consente a questa Applicazione di identificarlo e di
                    dargli accesso a servizi dedicati. I Dati Personali sono raccolti e conservati esclusivamente a
                    scopo di registrazione o di identificazione. I Dati raccolti sono solo quelli necessari a fornire il
                    servizio richiesto dall’Utente.</p>

                <p><strong>Registrazione diretta (questa Applicazione)</strong></p>
                <p>L’Utente si registra compilando il modulo di registrazione e fornendo direttamente a questa
                    Applicazione i propri Dati Personali.</p>
                <p>Dati Personali trattati: cognome; email; nome; numero di telefono, matricola universitaria.</p>
            </div>
            <div>
                <p><strong>Salvataggio e gestione di backup</strong></p>
                <p>Questo tipo di servizi permette il salvataggio e la gestione dei backup di questa Applicazione su
                    server esterni gestiti dal fornitore del servizio stesso. Questi backup possono comprendere sia il
                    codice sorgente ed i contenuti della stessa che i dati forniti a questa Applicazione da parte
                    dell'Utente.</p>

                <p><strong>Backup su Amazon S3 (Amazon S3)</strong></p>
                <p>Google Drive è un servizio di salvataggio e gestione di backup fornito da Amazon. Dati Personali
                    trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.</p>
                <p>Luogo del trattamento: Italia</p>
            </div>
        </div>

        <div class="viewPar BLOBAlignLeft">
            <p><strong class="userFormat1">Ulteriori informazioni sul trattamento dei Dati Personali</strong></p>
            <div>
                <p><strong>LocalStorage</strong></p>
                <p>localStorage permette a questa Applicazione di memorizzare e accedere ai dati direttamente dal
                    browser dell’Utente senza una data di scadenza.</p>
            </div>

            <div class="viewLineBreak">&nbsp;</div>

            <div class="viewPar BLOBAlignLeft">
                <p><strong class="userFormat1">Diritti dell’Utente</strong></p>
                <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
            </div>
            <strong class="userFormat1">In particolare, l’Utente ha il diritto di:</strong>
            <ol class="viewOL BLOBListSymbolDecimal">
                <li class="viewLI BLOBAlignLeft">revocare il consenso in ogni momento. L’Utente può revocare il consenso
                    al trattamento dei propri Dati Personali precedentemente espresso.
                </li>
                <li class="viewLI BLOBAlignLeft">opporsi al trattamento dei propri Dati. L’Utente può opporsi al
                    trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso.
                    Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.
                </li>
                <li class="viewLI BLOBAlignLeft">accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni
                    sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei
                    Dati trattati.
                </li>
                <li class="viewLI BLOBAlignLeft">verificare e chiedere la rettificazione. L’Utente può verificare la
                    correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.
                </li>
                <li class="viewLI BLOBAlignLeft">ottenere la limitazione del trattamento. Quando ricorrono determinate
                    condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il
                    Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
                </li>
                <li class="viewLI BLOBAlignLeft">ottenere la cancellazione o rimozione dei propri Dati Personali. Quando
                    ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte
                    del Titolare.
                </li>
                <li class="viewLI BLOBAlignLeft">ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente
                    ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da
                    dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli
                    ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti
                    automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è
                    parte o su misure contrattuali ad esso connesse.
                </li>
                <li class="viewLI BLOBAlignLeft">proporre reclamo. L’Utente può proporre un reclamo all’autorità di
                    controllo della protezione dei dati personali competente o agire in sede giudiziale.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>


            <div class="viewPar BLOBAlignLeft">
                <p><strong class="userFormat1">Dettagli sul diritto di opposizione</strong></p>
                <p>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di
                    cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti
                    hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
                <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto,
                    possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti
                    dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni
                    di questo documento.</p>
            </div>

            <div class="viewLineBreak">&nbsp;</div>

            <div class="viewPar BLOBAlignLeft">
                <p><strong class="userFormat1">Come esercitare i diritti</strong></p>
                <p>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di
                    contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e
                    evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>
            </div>

            <div class="viewLineBreak">&nbsp;</div>


            <div class="viewPar BLOBAlignLeft">
                <p><strong class="userFormat1">Ulteriori informazioni sul trattamento</strong></p>
                <p><strong class="userFormat1">Difesa in giudizio</strong></p>
                I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi
                preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa
                Applicazione o dei Servizi connessi da parte dell’Utente.
                L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per
                ordine delle autorità pubbliche.

                <p><strong class="userFormat1">Informative specifiche</strong></p>
                <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa
                    Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti
                    Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>

                <p><strong class="userFormat1">Log di sistema e manutenzione</strong></p>
                <p>Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali
                    servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le
                    interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>

                <p><strong class="userFormat1">Informazioni non contenute in questa policy</strong></p>
                <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in
                    qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>

                <p><strong class="userFormat1">Risposta alle richieste “Do Not Track”</strong></p>
                <p>Questa Applicazione non supporta le richieste “Do Not Track”.</p>
                <p>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a
                    consultare le rispettive privacy policy.</p>

                <p><strong class="userFormat1">Modifiche a questa privacy policy</strong></p>
                <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy
                    in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa
                    Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti
                    attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con
                    frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>

                <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare
                    provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
            </div>

            <div class="viewLineBreak">&nbsp;</div>

        </div>
    </div>
</div>
