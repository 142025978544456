import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {UserRoleGuard} from "./core/auth/guards/user-role.guard";
import {RolesEnum} from "./shared/services/local-storage.service";


export enum PathEnum {
    SIGN_IN = 'sign-in',
    SIGN_UP = 'sign-up',
    FORGOT_PASSWORD = 'forgot-password',
    RECOVER_PASSWORD = 'recover-password',
    HOME = 'home',
    USERS = 'users',
    CONFIRMATION_REQUIRED = 'confirmation-required',
    CONFIGURATION = 'configuration',
    ENABLE_ACCOUNT = 'enable-account',
    VERIFY_ACCOUNT = 'verify-account',
    STUDENTS = 'students',
    PROFILE = 'profile'
}

export enum PathConfigurationEnum {
    DOCTORAL_PROGRAMS = 'doctoral-programs',

}

export enum PathStudentEnum {
    STUDENT_DETAILS = 'student-list'
}


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'


    {path: '', pathMatch: 'full', redirectTo: PathEnum.HOME},
    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                canActivate: [NoAuthGuard],
                canActivateChild: [NoAuthGuard],
                path: PathEnum.FORGOT_PASSWORD,
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: PathEnum.RECOVER_PASSWORD,
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                canActivate: [NoAuthGuard],
                canActivateChild: [NoAuthGuard],
                path: PathEnum.SIGN_IN,
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                canActivate: [NoAuthGuard],
                canActivateChild: [NoAuthGuard],
                path: PathEnum.SIGN_UP,
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            },
            {
                path: PathEnum.ENABLE_ACCOUNT,
                loadChildren: () => import('app/modules/auth/enable-account/enable-account.module').then(m => m.EnableAccountModule)
            },
            {
                path: PathEnum.VERIFY_ACCOUNT,
                loadChildren: () => import('app/modules/auth/verify-email/verify-email.module').then(m => m.VerifyEmailModule)
            }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: PathEnum.HOME,
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
            {
                path: PathEnum.USERS,
                loadChildren: () => import('app/modules/admin/user-list/user-list.module').then(m => m.UserListModule)
            },
            {
                path: PathEnum.CONFIGURATION,
                loadChildren: () => import('app/modules/admin/configuration/configuration.module').then(m => m.ConfigurationModule)
            },
            {
                path: PathEnum.STUDENTS,
                loadChildren: () => import('app/modules/admin/students-list/students-list.module').then(m => m.StudentsListModule)
            },
            {
                path: PathEnum.PROFILE,
                data: {
                    ruoliAutorizzati: [RolesEnum.ROLE_personale_pta, RolesEnum.ROLE_docente, RolesEnum.ROLE_esterno]
                },
                canActivate: [UserRoleGuard],
                canActivateChild: [UserRoleGuard],
                loadChildren: () => import('app/layout/common/profile/profile.module').then(m => m.ProfileModule)
            }
        ]
    },

];
