/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClonaOffertaFormativaDto } from '../model/clonaOffertaFormativaDto';
import { Corso } from '../model/corso';
import { CorsoDTO } from '../model/corsoDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { OffertaFormativaDTO } from '../model/offertaFormativaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OfferteFormativeService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge un nuovo corso nell&#x27;offerta formativa relativa al ciclo di dottorato richiesto.
     * 
     * @param body Corso in offerta formativa da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato del quale si vuole aggiornare l&#x27;offerta formativa. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<Corso>;
    public addCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Corso>>;
    public addCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Corso>>;
    public addCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCorsoInOffertaFormativa.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling addCorsoInOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Corso>('post',`${this.basePath}/api/v1/offerte-formative/${encodeURIComponent(String(ciclo_dottorato_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archivia un corso esistente nell&#x27;offerta formativa relativa al ciclo di dottorato richiesto.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato del quale si vuole aggiornare l&#x27;offerta formativa. Non può essere nullo o vuoto.
     * @param id_corso Identificativo del corso che si vuole aggiornare. Non può essere nullo o vuoto.
     * @param archive Valore di archiviazione del corso. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiviaCorsoInOffertaFormativa(ciclo_dottorato_id: string, id_corso: string, archive: boolean, observe?: 'body', reportProgress?: boolean): Observable<Corso>;
    public archiviaCorsoInOffertaFormativa(ciclo_dottorato_id: string, id_corso: string, archive: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Corso>>;
    public archiviaCorsoInOffertaFormativa(ciclo_dottorato_id: string, id_corso: string, archive: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Corso>>;
    public archiviaCorsoInOffertaFormativa(ciclo_dottorato_id: string, id_corso: string, archive: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling archiviaCorsoInOffertaFormativa.');
        }

        if (id_corso === null || id_corso === undefined) {
            throw new Error('Required parameter id_corso was null or undefined when calling archiviaCorsoInOffertaFormativa.');
        }

        if (archive === null || archive === undefined) {
            throw new Error('Required parameter archive was null or undefined when calling archiviaCorsoInOffertaFormativa.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Corso>('put',`${this.basePath}/api/v1/offerte-formative/${encodeURIComponent(String(ciclo_dottorato_id))}/${encodeURIComponent(String(id_corso))}/archiviazione`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clona un&#x27;offerta formativa totale o parziale tra cicli di dottorato già esistenti.
     * 
     * @param body Parametri per la clonazione.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clonaOffertaFormativaInCicloDottorato(body: ClonaOffertaFormativaDto, observe?: 'body', reportProgress?: boolean): Observable<Array<Corso>>;
    public clonaOffertaFormativaInCicloDottorato(body: ClonaOffertaFormativaDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Corso>>>;
    public clonaOffertaFormativaInCicloDottorato(body: ClonaOffertaFormativaDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Corso>>>;
    public clonaOffertaFormativaInCicloDottorato(body: ClonaOffertaFormativaDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling clonaOffertaFormativaInCicloDottorato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Corso>>('post',`${this.basePath}/api/v1/offerte-formative/cloning`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;offerta formativa relativa ad un ciclo di dottorato, dato il suo identificativo.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato del quale si richiede l&#x27;offerta formativa. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOffertaFormativaById(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Corso>>;
    public getOffertaFormativaById(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Corso>>>;
    public getOffertaFormativaById(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Corso>>>;
    public getOffertaFormativaById(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getOffertaFormativaById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Corso>>('get',`${this.basePath}/api/v1/offerte-formative/${encodeURIComponent(String(ciclo_dottorato_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;offerta formativa relativa ad un ciclo di dottorato, dato il suo identificativo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfferteFormative(observe?: 'body', reportProgress?: boolean): Observable<Array<OffertaFormativaDTO>>;
    public getOfferteFormative(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OffertaFormativaDTO>>>;
    public getOfferteFormative(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OffertaFormativaDTO>>>;
    public getOfferteFormative(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OffertaFormativaDTO>>('get',`${this.basePath}/api/v1/offerte-formative`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna un corso esistente nell&#x27;offerta formativa relativa al ciclo di dottorato richiesto.
     * 
     * @param body Corso in offerta formativa da aggiornare.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato del quale si vuole aggiornare l&#x27;offerta formativa. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<Corso>;
    public updateCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Corso>>;
    public updateCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Corso>>;
    public updateCorsoInOffertaFormativa(body: CorsoDTO, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCorsoInOffertaFormativa.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling updateCorsoInOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Corso>('put',`${this.basePath}/api/v1/offerte-formative/${encodeURIComponent(String(ciclo_dottorato_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
