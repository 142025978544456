/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ruoli degli utenti. Ruoli previsti: *ADMIN*, *DOCENTE*, *PERSONALE_ATA*, *STUDENTE*, *ESTERNO*
 */
export type AuthorityType = 'ADMIN' | 'DOCENTE' | 'PERSONALE_PTA' | 'STUDENTE' | 'ESTERNO';

export const AuthorityType = {
    ADMIN: 'ADMIN' as AuthorityType,
    DOCENTE: 'DOCENTE' as AuthorityType,
    PERSONALEPTA: 'PERSONALE_PTA' as AuthorityType,
    STUDENTE: 'STUDENTE' as AuthorityType,
    ESTERNO: 'ESTERNO' as AuthorityType
};