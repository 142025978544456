/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologia stati di un piano di studi. Valori previsti: *BOZZA*, *PRESENTATO*, *APPROVATO_SUPERVISORE*, *APPROVATO_COORDINATORE*, *IN_MODIFICA*
 */
export type PianoStudiStatus = 'BOZZA' | 'PRESENTATO' | 'APPROVATO_SUPERVISORE' | 'APPROVATO_COORDINATORE' | 'IN_MODIFICA';

export const PianoStudiStatus = {
    BOZZA: 'BOZZA' as PianoStudiStatus,
    PRESENTATO: 'PRESENTATO' as PianoStudiStatus,
    APPROVATOSUPERVISORE: 'APPROVATO_SUPERVISORE' as PianoStudiStatus,
    APPROVATOCOORDINATORE: 'APPROVATO_COORDINATORE' as PianoStudiStatus,
    INMODIFICA: 'IN_MODIFICA' as PianoStudiStatus
};