import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isArray} from "lodash";
import moment from "moment";

export class CustomValidators {

    /**
     * Validatore che controlla se il dominio della mail è valido
     * @param notForbiddenDomain
     */



    static forbiddenDomain(notForbiddenDomain: string[]): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            const controlEmail = formControl;

            if (!controlEmail || !notForbiddenDomain) {
                return null;
            }

            const currentEmail: string = controlEmail.value;
            const emailDomain: string = currentEmail.split('@')[1];
            if (controlEmail.hasError('forbiddenDomain')) {
                delete controlEmail.errors.forbiddenDomain;
                controlEmail.updateValueAndValidity();
            }
            if (notForbiddenDomain.indexOf(emailDomain) !== -1) {
                return null;
            }
            const errors = {forbiddenDomain: true};
            controlEmail?.setErrors(errors);
            return errors;
        };
    }

    static atLeastOneFielFisRequired(name: string | string[], surname: string, text = 'form.name_or_surname_required'): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlName = formGroup.get(name);
            const controlSurname = formGroup.get(surname);

            const error = {checkNameSurname: text};

            const isError = isArray(controlName?.value) ? (controlName?.value?.length > 0 || controlSurname?.value?.trim()) : (controlName?.value?.trim() || controlSurname?.value?.trim())
            if (isError) {
                controlName?.setErrors(null);
                controlSurname?.setErrors(null);
                return null;
            } else {
                controlName?.setErrors(error);
                controlSurname?.setErrors(error);
            }

            return error;
        };

    }

    static rangeDataValidator(dataDa: string, dataA: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {checkRangeData: 'form.invalid_range_data'};

            if ((controlDataDa?.value && controlDataA?.value) || (!controlDataDa?.value && !controlDataA?.value)) {
                controlDataDa?.setErrors(null);
                controlDataA?.setErrors(null);
                return null;
            } else if ((!controlDataDa?.value || !controlDataA.value)) {
                controlDataDa?.setErrors(error);
                controlDataA?.setErrors(error);
            }
            return error;
        };
    }

    static noSpace(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control?.value == null) {
                return null;
            }
            return control?.value?.includes(' ') ? {
                noSpace: true,
            } : null;
        };
    }

    static dataAMaggioreDiDataA(dataDa: string, dataA: string, format: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {dataAMaggioreDiDataA: 'form.error_data_range'};
            if (!controlDataA?.value) {
                return null;
            } else if (!controlDataDa.value || moment(controlDataDa.value, format).isAfter(moment(controlDataA.value, format))) {
                return error;
            } else {
                return null;
            }
        };
    }

}
