/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologia di anni di un ciclo di dottorato. Valori previsti: *I*, *II*, *III*, *IV*
 */
export type AnnoType = 'I' | 'II' | 'III' | 'IV';

export const AnnoType = {
    I: 'I' as AnnoType,
    II: 'II' as AnnoType,
    III: 'III' as AnnoType,
    IV: 'IV' as AnnoType
};