/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di sottocampi supportati. Valori previsti: *TESTO_LIBERO*, *NUMERO*, *DATA*, *SELECT_SINGLE_CHOICE*, *ALLEGATO*, *TIME_RANGE*
 */
export type ValoreType = 'TESTO_LIBERO' | 'NUMERO' | 'DATA' | 'SELECT_SINGLE_CHOICE' | 'ALLEGATO' | 'TIME_RANGE';

export const ValoreType = {
    TESTOLIBERO: 'TESTO_LIBERO' as ValoreType,
    NUMERO: 'NUMERO' as ValoreType,
    DATA: 'DATA' as ValoreType,
    SELECTSINGLECHOICE: 'SELECT_SINGLE_CHOICE' as ValoreType,
    ALLEGATO: 'ALLEGATO' as ValoreType,
    TIMERANGE: 'TIME_RANGE' as ValoreType
};