/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GrantType } from '../model/grantType';
import { LoginResponse } from '../model/loginResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce un nuovo set di token d&#x27;accesso per l&#x27;utente se la combinazione di username e password fornita è valida.
     * 
     * @param username 
     * @param password 
     * @param grant_type 
     * @param X_recaptcha_token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginForm(username: string, password: string, grant_type: GrantType, X_recaptcha_token: string, observe?: 'body', reportProgress?: boolean): Observable<LoginResponse>;
    public loginForm(username: string, password: string, grant_type: GrantType, X_recaptcha_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponse>>;
    public loginForm(username: string, password: string, grant_type: GrantType, X_recaptcha_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponse>>;
    public loginForm(username: string, password: string, grant_type: GrantType, X_recaptcha_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling login.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling login.');
        }

        if (grant_type === null || grant_type === undefined) {
            throw new Error('Required parameter grant_type was null or undefined when calling login.');
        }

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling login.');
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (username !== undefined) {
            formParams = formParams.append('username', <any>username) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (grant_type !== undefined) {
            formParams = formParams.append('grant_type', <any>grant_type) as any || formParams;
        }

        return this.httpClient.request<LoginResponse>('post',`${this.basePath}/api/v1/auth/login`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Esegue la logout dell&#x27;utente, invalidandone il token.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUser(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public logoutUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public logoutUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public logoutUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/auth/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce un nuovo set di token d&#x27;accesso a partire da un refresh token valido dell&#x27;utente.
     * 
     * @param refresh_token 
     * @param username 
     * @param grant_type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshTokenForm(refresh_token: string, username: string, grant_type: GrantType, observe?: 'body', reportProgress?: boolean): Observable<LoginResponse>;
    public refreshTokenForm(refresh_token: string, username: string, grant_type: GrantType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponse>>;
    public refreshTokenForm(refresh_token: string, username: string, grant_type: GrantType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponse>>;
    public refreshTokenForm(refresh_token: string, username: string, grant_type: GrantType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refresh_token === null || refresh_token === undefined) {
            throw new Error('Required parameter refresh_token was null or undefined when calling refreshToken.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling refreshToken.');
        }

        if (grant_type === null || grant_type === undefined) {
            throw new Error('Required parameter grant_type was null or undefined when calling refreshToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (refresh_token !== undefined) {
            formParams = formParams.append('refresh_token', <any>refresh_token) as any || formParams;
        }
        if (username !== undefined) {
            formParams = formParams.append('username', <any>username) as any || formParams;
        }
        if (grant_type !== undefined) {
            formParams = formParams.append('grant_type', <any>grant_type) as any || formParams;
        }

        return this.httpClient.request<LoginResponse>('post',`${this.basePath}/api/v1/auth/token`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
