/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CfgGruppoCoordinamentoForm } from '../model/cfgGruppoCoordinamentoForm';
import { CicloDottorato } from '../model/cicloDottorato';
import { CicloDottoratoDTO } from '../model/cicloDottoratoDTO';
import { CicloDottoratoInfoDTO } from '../model/cicloDottoratoInfoDTO';
import { CicloDottoratoSummaryDTO } from '../model/cicloDottoratoSummaryDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CicliDottoratoService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge un nuovo ciclo di dottorato.
     * 
     * @param body Ciclo di dottorato da aggiungere.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCicloDottorato(body: CicloDottoratoDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public addCicloDottorato(body: CicloDottoratoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public addCicloDottorato(body: CicloDottoratoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public addCicloDottorato(body: CicloDottoratoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCicloDottorato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloDottorato>('post',`${this.basePath}/api/v1/cicli-dottorato`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il Coordinatore per un ciclo di dottorato.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param coordinatore_id Identificativo del coordinatore del ciclo di dottorato da impostare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public configuraCoordinatore(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public configuraCoordinatore(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public configuraCoordinatore(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public configuraCoordinatore(ciclo_dottorato_id: string, coordinatore_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling configuraCoordinatore.');
        }

        if (coordinatore_id === null || coordinatore_id === undefined) {
            throw new Error('Required parameter coordinatore_id was null or undefined when calling configuraCoordinatore.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (coordinatore_id !== undefined && coordinatore_id !== null) {
            queryParameters = queryParameters.set('coordinatore_id', <any>coordinatore_id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}/coordinator`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il Coordinatore PTA per un ciclo di dottorato.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param coordinatore_id Identificativo del coordinatore PTA del ciclo di dottorato da impostare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public configuraCoordinatorePta(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public configuraCoordinatorePta(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public configuraCoordinatorePta(ciclo_dottorato_id: string, coordinatore_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public configuraCoordinatorePta(ciclo_dottorato_id: string, coordinatore_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling configuraCoordinatorePta.');
        }

        if (coordinatore_id === null || coordinatore_id === undefined) {
            throw new Error('Required parameter coordinatore_id was null or undefined when calling configuraCoordinatorePta.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (coordinatore_id !== undefined && coordinatore_id !== null) {
            queryParameters = queryParameters.set('coordinatore_id', <any>coordinatore_id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}/coordinator-pta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il Gruppo di Coordinamento per un ciclo di dottorato.
     * 
     * @param body Form per definire il nuovo gruppo di coordinamento del ciclo di dottorato
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public configuraGruppoCoordinamento(body: CfgGruppoCoordinamentoForm, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public configuraGruppoCoordinamento(body: CfgGruppoCoordinamentoForm, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public configuraGruppoCoordinamento(body: CfgGruppoCoordinamentoForm, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public configuraGruppoCoordinamento(body: CfgGruppoCoordinamentoForm, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling configuraGruppoCoordinamento.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling configuraGruppoCoordinamento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}/coordination-group`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abilita/disabilita le iscrizioni per un ciclo di dottorato.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param activate Valore di stato del ciclo di dottorato. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableOrDisableRegistrationsInCicloDottorato(ciclo_dottorato_id: string, activate: boolean, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public enableOrDisableRegistrationsInCicloDottorato(ciclo_dottorato_id: string, activate: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public enableOrDisableRegistrationsInCicloDottorato(ciclo_dottorato_id: string, activate: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public enableOrDisableRegistrationsInCicloDottorato(ciclo_dottorato_id: string, activate: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling enableOrDisableRegistrationsInCicloDottorato.');
        }

        if (activate === null || activate === undefined) {
            throw new Error('Required parameter activate was null or undefined when calling enableOrDisableRegistrationsInCicloDottorato.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activate !== undefined && activate !== null) {
            queryParameters = queryParameters.set('activate', <any>activate);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}/registrations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce tutti i cicli di dottorato.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicliDottorato(observe?: 'body', reportProgress?: boolean): Observable<Array<CicloDottoratoSummaryDTO>>;
    public getCicliDottorato(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CicloDottoratoSummaryDTO>>>;
    public getCicliDottorato(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CicloDottoratoSummaryDTO>>>;
    public getCicliDottorato(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CicloDottoratoSummaryDTO>>('get',`${this.basePath}/api/v1/cicli-dottorato`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce un ciclo di dottorato dato il suo identificativo.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicloDottoratoById(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public getCicloDottoratoById(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public getCicloDottoratoById(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public getCicloDottoratoById(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCicloDottoratoById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('get',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di informazioni minime per tutti i cicli di dottorato presenti.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoCicliDottoratoView(observe?: 'body', reportProgress?: boolean): Observable<Array<CicloDottoratoInfoDTO>>;
    public getInfoCicliDottoratoView(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CicloDottoratoInfoDTO>>>;
    public getInfoCicliDottoratoView(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CicloDottoratoInfoDTO>>>;
    public getInfoCicliDottoratoView(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CicloDottoratoInfoDTO>>('get',`${this.basePath}/api/v1/cicli-dottorato/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di informazioni minime per popolare i filtri.
     * 
     * @param id_ciclo_dottorato Identificativo del ciclo di dottorato da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoCicloDottoratoById(id_ciclo_dottorato: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottoratoInfoDTO>;
    public getInfoCicloDottoratoById(id_ciclo_dottorato: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottoratoInfoDTO>>;
    public getInfoCicloDottoratoById(id_ciclo_dottorato: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottoratoInfoDTO>>;
    public getInfoCicloDottoratoById(id_ciclo_dottorato: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_ciclo_dottorato === null || id_ciclo_dottorato === undefined) {
            throw new Error('Required parameter id_ciclo_dottorato was null or undefined when calling getInfoCicloDottoratoById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottoratoInfoDTO>('get',`${this.basePath}/api/v1/cicli-dottorato/info/${encodeURIComponent(String(id_ciclo_dottorato))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna un ciclo di dottorato.
     * 
     * @param body Ciclo di dottorato da aggiornare.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCicloDottorato(body: CicloDottoratoDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public updateCicloDottorato(body: CicloDottoratoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public updateCicloDottorato(body: CicloDottoratoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public updateCicloDottorato(body: CicloDottoratoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCicloDottorato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna lo stato di un ciclo di dottorato.
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param status Valore di stato del ciclo di dottorato. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatusCicloDottorato(ciclo_dottorato_id: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public updateStatusCicloDottorato(ciclo_dottorato_id: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public updateStatusCicloDottorato(ciclo_dottorato_id: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public updateStatusCicloDottorato(ciclo_dottorato_id: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling updateStatusCicloDottorato.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateStatusCicloDottorato.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/cicli-dottorato/${encodeURIComponent(String(ciclo_dottorato_id))}/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
