/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Lingue supportate per la redazione della tesi di dottorato. Valori previsti: *INGLESE*, *ITALIANO*, *OTHER*
 */
export type SupportedLanguages = 'INGLESE' | 'ITALIANO' | 'OTHER';

export const SupportedLanguages = {
    INGLESE: 'INGLESE' as SupportedLanguages,
    ITALIANO: 'ITALIANO' as SupportedLanguages,
    OTHER: 'OTHER' as SupportedLanguages
};