/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { RegistroAttivitaPdfForm } from '../model/registroAttivitaPdfForm';
import { StudentsFilterForm } from '../model/studentsFilterForm';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExportService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce il byte stream array di un file PDF contenente i profili degli studenti iscritti alla piattaforma, secondo i filtri richiesti.
     *
     * @param studente_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfAnagraficaStudente(studente_id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfAnagraficaStudente(studente_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfAnagraficaStudente(studente_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfAnagraficaStudente(studente_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (studente_id === null || studente_id === undefined) {
            throw new Error('Required parameter studente_id was null or undefined when calling ottieniPdfAnagraficaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/export/anagrafica-studente/${encodeURIComponent(String(studente_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente la scheda budget dello studente richiesto.
     *
     * @param studente_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfBudgetStudente(studente_id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfBudgetStudente(studente_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfBudgetStudente(studente_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfBudgetStudente(studente_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (studente_id === null || studente_id === undefined) {
            throw new Error('Required parameter studente_id was null or undefined when calling ottieniPdfBudgetStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/export/budget/${encodeURIComponent(String(studente_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'

            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente il piano studi dello studente richiesto.
     *
     * @param studente_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfPianoStudiStudente(studente_id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfPianoStudiStudente(studente_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfPianoStudiStudente(studente_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfPianoStudiStudente(studente_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (studente_id === null || studente_id === undefined) {
            throw new Error('Required parameter studente_id was null or undefined when calling ottieniPdfPianoStudiStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/export/piano-studi/${encodeURIComponent(String(studente_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente il registro attivita dello studente richiesto.
     *
     * @param body Filtri da applicare alla ricerca.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfRegistroAttivitaStudente(body: RegistroAttivitaPdfForm, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfRegistroAttivitaStudente(body: RegistroAttivitaPdfForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfRegistroAttivitaStudente(body: RegistroAttivitaPdfForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfRegistroAttivitaStudente(body: RegistroAttivitaPdfForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ottieniPdfRegistroAttivitaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/export/registro-attivita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente la scheda tesi di dottorato dello studente richiesto.
     *
     * @param studente_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfSchedaTesiStudente(studente_id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfSchedaTesiStudente(studente_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfSchedaTesiStudente(studente_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfSchedaTesiStudente(studente_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (studente_id === null || studente_id === undefined) {
            throw new Error('Required parameter studente_id was null or undefined when calling ottieniPdfSchedaTesiStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/export/scheda-tesi/${encodeURIComponent(String(studente_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente i profili degli studenti iscritti alla piattaforma, secondo i filtri richiesti.
     *
     * @param body Filtri da applicare alla ricerca.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfStudenti(body: StudentsFilterForm, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfStudenti(body: StudentsFilterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfStudenti(body: StudentsFilterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfStudenti(body: StudentsFilterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ottieniPdfStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/export/students`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: "blob" as "json"
            }
        );
    }

    /**
     * Restituisce il byte stream array di un file PDF contenente i profili degli utenti in piattaforma, eventualmente filtrati.
     *
     * @param utente
     * @param ruolo
     * @param abilitato
     * @param verificato
     * @param numeroCiclo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ottieniPdfUtentiForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public ottieniPdfUtentiForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public ottieniPdfUtentiForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public ottieniPdfUtentiForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (utente !== undefined) {
            formParams = formParams.append('utente', <any>utente) as any || formParams;
        }
        if (ruolo !== undefined) {
            formParams = formParams.append('ruolo', <any>ruolo) as any || formParams;
        }
        if (abilitato !== undefined) {
            formParams = formParams.append('abilitato', <any>abilitato) as any || formParams;
        }
        if (verificato !== undefined) {
            formParams = formParams.append('verificato', <any>verificato) as any || formParams;
        }
        if (numeroCiclo !== undefined) {
            formParams = formParams.append('numeroCiclo', <any>numeroCiclo) as any || formParams;
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/export/users`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

}
