import {Component} from '@angular/core';
import {LocalStorageService} from "./shared/services/local-storage.service";
import {TranslocoService} from "@ngneat/transloco";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(private localStorageService: LocalStorageService, private translocoService: TranslocoService) {
        this.translocoService.setActiveLang(this.localStorageService.getActiveLang() || 'it')
    }
}
