import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorI18n} from "./shared/mat-paginator-i18n/mat-paginator-i18n";
import {ApiModule, Configuration} from "../api-clients/generated/services";
import {LocalStorageService} from "./shared/services/local-storage.service";
import {environment} from "../environments/environment";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RefreshTokenInterceptor} from "./shared/interceptors/refresh-token.interceptor";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {CUSTOM_DATE_FORMAT} from "./layout/common/generic-components/form/form.module";

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        ApiModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    providers: [
        MatDatepickerModule,
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
        { provide: MAT_DATE_LOCALE, useValue: 'it' },
        {provide: MatPaginatorIntl, useClass: MatPaginatorI18n},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: Configuration,
            useFactory: (localStorageService: LocalStorageService) => {
                return new Configuration(
                    {
                        basePath: environment.servicesUrl,
                        accessToken: () => {
                            return localStorageService.getAccessToken();
                        },
                    });


            },
            deps: [LocalStorageService],
            multi: false,
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
