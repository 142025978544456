import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Injectable} from "@angular/core";
import {isEmpty} from 'lodash';
import {formGroupConfigInterface} from '../generic-components.interface';
import {emailRegex} from "../../../../shared/constants/regex";
import {isNil} from "lodash";
import {CustomValidators} from "../../../../shared/validators/custom-validators";


@Injectable({
    providedIn: 'root'
})


export class FormModuleService {

    constructor() {
    }

    formControl(name: string, formGroup: FormGroup): FormControl {
        return formGroup.get(name) as FormControl;
    }


    createFormGroup(formGroupConfig: formGroupConfigInterface[], formGroup: FormGroup, validatorFns?: ValidatorFn[]) {
        formGroupConfig.forEach(form => {
            formGroup.addControl(form.name, new FormControl(null))
            if (form.required) {
                this.formControl(form.name, formGroup).addValidators(Validators.required)
            }
            if (form?.regex?.pattern) {
                this.formControl(form.name, formGroup).addValidators(Validators.pattern(new RegExp(form?.regex?.pattern)))
            }
            if (!isNil(form?.max?.number)) {
                this.formControl(form.name, formGroup).addValidators(Validators.max(form.max.number))
            }
            if (form?.maxlength) {
                this.formControl(form.name, formGroup).addValidators(Validators.maxLength(form?.maxlength));
            }
            /*if (form?.isSpace) {
                this.formControl(form.name, formGroup).addValidators(CustomValidators.noSpace());
            }*/
            if (form.disable) {
                this.formControl(form.name, formGroup).disable();
            }
        })
        if (validatorFns && validatorFns.length > 0) {
            formGroup.setValidators(validatorFns);
        }
    }
}
