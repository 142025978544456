import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testo-privacy',
  templateUrl: './testo-privacy.component.html',
  styleUrls: ['./testo-privacy.component.scss']
})
export class TestoPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
