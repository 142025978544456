<div class="privacy">
    <div class="corpopagina">
        <div class="viewPar BLOBAlignLeft">
            <h3><strong class="userFormat1">CONDIZIONI GENERALI</strong></h3>
            <p>Le presenti condizioni regolano l’uso della piattaforma web Gestione Carriere (di seguito piattaforma) di
                proprietà della South Engineering S.r.l., società di diritto italiano, corrente in Afragola (Napoli) al
                Corso Garibaldi 38 ed iscritta presso il Registro delle Imprese di Napoli al n. 08796931213.</p>
            <p>Sono state redatte in maniera estremamente sintetica e il più possibile chiara al fine di agevolarne la
                lettura, la comprensione e l’effettiva approvazione. </p>
            <p>E’ solo per questa ragione che talora si è preferito soffermarsi più sugli obblighi, che non sui diritti
                dell’utente, inderogabilmente predeterminati dal Codice del consumo (D. Lgs. 6 settembre 2005, n. 206),
                consultabile utilizzando il seguente link https://www.codicedelconsumo.it . </p>
            <p>Per eventuali chiarimenti è sempre possibile contattare South Engineering all’indirizzo di posta
                amministrazione@southengineering.it </p>
        </div>
        <div>
            <strong class="userFormat1">1. FUNZIONE DELLA PIATTAFORMA</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">La piattaforma consente la gestione delle carriere dei dottorandi
                    iscritti ad un ciclo di dottorato presso l’università che ha acquistato licenza d’uso della
                    piattaforma stessa.
                </li>
                <li class="viewLI BLOBAlignLeft">A ciascun utente che si iscrive alla piattaforma viene associato un
                    ruolo, che corrisponde al ruolo che ricopre nell’ateneo universitario (ad es. Studente, Docente,
                    Supervisore, etc). Tutte le informazioni che vengono inserite dall’utente, sono visibili agli utenti
                    con privilegi maggiori. I dati degli studenti sono ad esempio visibili a tutti i docenti ed al
                    personale tecnico amministrativo che gestisce il ciclo di dottorato a cui gli studenti sono
                    iscritti.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">2. NAVIGAZIONE SULLA PIATTAFORMA</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">La piattaforma è accessibile all’utente previa registrazione.</li>
                <li class="viewLI BLOBAlignLeft">L’accesso alla piattaforma implica in ogni caso l’accettazione delle
                    condizioni, che ne regolano l’uso, da parte dell’utente, che è tenuto all’atto dell’accesso a
                    prenderne visione, potendo le stesse essere modificate in qualsiasi momento da South Engineering
                    S.r.l. mediante l’aggiornamento della presente pagina.
                </li>
                <li class="viewLI BLOBAlignLeft">L’accesso alla piattaforma, che di norma è possibile tutti i giorni e a
                    tutte le ore, può essere in qualsiasi momento sospeso, per periodi di qualsiasi durata, anche in
                    difetto di preavviso, in caso di manutenzione o di forza maggiore, senza che ciò implichi alcuna
                    responsabilità da parte di South Engineering S.r.l. nei confronti dell’utente.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">3. REGISTRAZIONE ALLA PIATTAFORMA</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">L’utente, prima di poter utilizzare le funzionalità offerte dalla
                    piattaforma, è tenuto a compilare i campi obbligatori del modulo di registrazione, inserendo i dati
                    personali richiesti.
                </li>
                <li class="viewLI BLOBAlignLeft">La registrazione è consentita esclusivamente agli utenti che abbiano
                    già compiuto i diciotto anni e non risultino inabilitati, interdetti o comunque soggetti ad altri
                    provvedimenti limitativi della capacità giuridica d’agire.
                </li>
                <li class="viewLI BLOBAlignLeft">L’utente è tenuto a garantire sotto ogni responsabilità di legge la
                    veridicità dei dati personali forniti a South Engineering S.r.l. al momento della registrazione.
                </li>
                <li class="viewLI BLOBAlignLeft">L’utente è tenuto a mantenere riservati i propri dati di login e a
                    comunicare immediatamente all’indirizzo amministrazione@southengineering.it l’eventuale sottrazione
                    o divulgazione involontaria degli stessi.
                </li>
                <li class="viewLI BLOBAlignLeft">South Engineering S.r.l. non risponde per le azioni eseguite da terzi
                    mediante l’utilizzo dei dati di login o dell’identità dell’utente, che sarà in ogni caso tenuto a
                    rispondere delle stesse.
                </li>
                <li class="viewLI BLOBAlignLeft">In caso di sospetto o effettivo utilizzo da parte di terzi dei dati di
                    login o dell’identità dell’utente, South Engineering S.r.l. sospenderà, anche senza preavviso,
                    temporaneamente l’account dello stesso, riservandosi di chiuderlo definitivamente.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">4. LIMITAZIONE DELLA RESPONSABILITA</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">South Engineering S.r.l. non risponde di eventuali errori o omissioni
                    presenti nei contenuti pubblicati sulla piattaforma o degli eventuali problemi tecnici che dovessero
                    insorgere nell’utilizzo dello stesso.
                </li>
                <li class="viewLI BLOBAlignLeft">South Engineering S.r.l. non è responsabile né a titolo contrattuale né
                    a titolo extracontrattuale né a qualsiasi altro titolo di qualunque danno o perdita che dovesse
                    eventualmente subire l’utente o un terzo in occasione o in conseguenza dell’utilizzo della
                    piattaforma.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">5. RISOLUZIONE DEL RAPPORTO</strong>
            <p><span>South Engineering  S.r.l. può inibire in via temporanea o permanete di servirsi della piattaforma all’utente:</span>
            </p>
            <ol class="viewOL BLOBListSymbolDecimal">
                <li class="viewLI BLOBAlignLeft">che ne faccia un uso improprio o illegale;</li>
                <li class="viewLI BLOBAlignLeft">che copi, riproduca, ripubblichi, scarichi, posti, diffonda, trasmetta
                    ovvero utilizzi altrimenti con qualsiasi modalità i contenuti pubblicati sulla piattaforma per scopi
                    commerciali e non esclusivamente personali;
                </li>
                <li class="viewLI BLOBAlignLeft">che rifiuti ingiustificatamente di ricevere i prodotti ordinati o di
                    pagare i prodotti consegnati;
                </li>
                <li class="viewLI BLOBAlignLeft">che interferisca, danneggi o manometta la piattaforma;</li>
                <li class="viewLI BLOBAlignLeft">che si renda gravemente inadempiente rispetto alle presenti
                    condizioni.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">6. RISERVATEZZA DEI DATI E SICUREZZA DELLE INFORMAZIONI</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">South Engineering S.r.l. si obbliga a proteggere la riservatezza dei
                    dati personali degli utenti, che verranno trattati conformemente all’informativa sulla privacy
                    disponibile nella piattaforma.
                </li>
                <li class="viewLI BLOBAlignLeft">South Engineering S.r.l. si impegna a tutelare la sicurezza delle
                    informazioni immesse sulla piattaforma dagli utenti; non essendo tuttavia possibile allo stato
                    assicurare, nonostante l’implementazione dei più avanzati protocolli, l’assoluta sicurezza della
                    trasmissione delle informazioni via internet, essa avverrà a rischio dell’utente.
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">7. LEGGE APPLICABILE E FORO COMPETENTE IN CASO DI CONTROVERSIA</strong>
            <ol class="viewOL BLOBListSymbolDecimal" type="a">
                <li class="viewLI BLOBAlignLeft">Le eventuali controversie che dovessero insorgere tra l’utente e la
                    South Engineering S.r.l. circa l’interpretazione e l’applicazione delle presenti condizioni verranno
                    regolate dalla Legge italiana.
                </li>
                <li class="viewLI BLOBAlignLeft">Nei soli casi in cui non trova applicazione l’art. 33 del Codice del
                    Consumo (D. Lgs. 6 settembre 2005, n. 206), che individua il Giudice competente in quello del luogo
                    in cui l’utente risiede o ha eletto domicilio, la competenza a conoscere delle eventuali
                    controversie è devoluta in via esclusiva al Foro di Santa Maria Capua Vetere.
                </li>
                <li class="viewLI BLOBAlignLeft">L’utente in caso di controversia, oltre che al Giudice italiano, può
                    alternativamente ricorrere ai sensi dell’art. 14 par. 1 del Regolamento UE 524/2013 alla Piattaforma
                    di Risoluzione Online per la Risoluzione delle Controversie in via stragiudiziale (Piattaforma ODR
                    Europea), utilizzando il seguente link http://ec.europa.eu/odr .
                </li>
            </ol>
            <div class="viewLineBreak">&nbsp;</div>
        </div>

        <div>
            <strong class="userFormat1">8. SPECIFICA APPROVAZIONE DELLE CONDIZIONI</strong>
            <p><span>L’Utente ai sensi degli art. 1341 e 1342del Codice civile, all’atto e per effetto della registrazione alla piattaforma, conferma di aver letto, compreso e specificamente approvato le condizioni  2.b), 2.c), 3.c), 4.a), 4.b), 5., 6.b), 7.b).</span>
            </p>
            <div class="viewLineBreak">&nbsp;</div>
        </div>


    </div>
</div>
