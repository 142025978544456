/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthorityType } from '../model/authorityType';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { PagePlatformUserDTO } from '../model/pagePlatformUserDTO';
import { PlatformUser } from '../model/platformUser';
import { PlatformUserInfoDTO } from '../model/platformUserInfoDTO';
import { StudentInfoDTO } from '../model/studentInfoDTO';
import { StudentsFilterForm } from '../model/studentsFilterForm';
import { User } from '../model/user';
import { UserRegistrationForm } from '../model/userRegistrationForm';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Attiva/blocca un account utente a partire dal suo identificativo. Se bloccato, tutti i token associati all&#x27;utente vengono invalidati.
     *
     * @param user_id Identificativo dell&#x27;utente da attivare. Non può essere nullo o vuoto.
     * @param role 
     * @param matricola 
     * @param dataIscrizione 
     * @param ssd 
     * @param dipartimento 
     * @param numeroCellulare 
     * @param activate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateOrLockAccountForm(user_id: string, role?: AuthorityType, matricola?: string, dataIscrizione?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, activate?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public activateOrLockAccountForm(user_id: string, role?: AuthorityType, matricola?: string, dataIscrizione?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, activate?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public activateOrLockAccountForm(user_id: string, role?: AuthorityType, matricola?: string, dataIscrizione?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, activate?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public activateOrLockAccountForm(user_id: string, role?: AuthorityType, matricola?: string, dataIscrizione?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, activate?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling activateOrLockAccount.');
        }








        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (role !== undefined) {
            formParams = formParams.append('role', <any>role) as any || formParams;
        }
        if (matricola !== undefined) {
            formParams = formParams.append('matricola', <any>matricola) as any || formParams;
        }
        if (dataIscrizione !== undefined) {
            formParams = formParams.append('dataIscrizione', <any>dataIscrizione) as any || formParams;
        }
        if (ssd !== undefined) {
            formParams = formParams.append('ssd', <any>ssd) as any || formParams;
        }
        if (dipartimento !== undefined) {
            formParams = formParams.append('dipartimento', <any>dipartimento) as any || formParams;
        }
        if (numeroCellulare !== undefined) {
            formParams = formParams.append('numeroCellulare', <any>numeroCellulare) as any || formParams;
        }
        if (activate !== undefined) {
            formParams = formParams.append('activate', <any>activate) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge/rimuove il ruolo di amministratore ad un account utente a partire dal suo identificativo.
     * Se rimosso, tutti i token associati all&#x27;utente vengono invalidati.La modifica può avvenire solo se l&#x27;utente target è un docente.
     * @param user_id Identificativo dell&#x27;utente da attivare. Non può essere nullo o vuoto.
     * @param activate Valore di abilitazione del ruolo. Se settato a true, il ruolo viene aggiunto. Se settato a false, il ruolo viene rimosso. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrRemoveAdminRoleToUser(user_id: string, activate: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public addOrRemoveAdminRoleToUser(user_id: string, activate: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public addOrRemoveAdminRoleToUser(user_id: string, activate: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public addOrRemoveAdminRoleToUser(user_id: string, activate: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling addOrRemoveAdminRoleToUser.');
        }

        if (activate === null || activate === undefined) {
            throw new Error('Required parameter activate was null or undefined when calling addOrRemoveAdminRoleToUser.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activate !== undefined && activate !== null) {
            queryParameters = queryParameters.set('activate', <any>activate);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/admin-role/${encodeURIComponent(String(user_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un utente a partire dal suo identificativo e ne invalida tutti gli eventuali token.
     *
     * @param user_id Identificativo dell&#x27;utente da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteUser(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteUser(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteUser(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il profilo dettagliato dell&#x27;utente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlatformUser(observe?: 'body', reportProgress?: boolean): Observable<PlatformUserInfoDTO>;
    public getPlatformUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlatformUserInfoDTO>>;
    public getPlatformUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlatformUserInfoDTO>>;
    public getPlatformUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PlatformUserInfoDTO>('get',`${this.basePath}/api/v1/user/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli studenti filtrati per ciclo di dottorato e altri filtri opzionali.
     * 
     * @param body Filtri da applicare alla ricerca.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudents(body: StudentsFilterForm, observe?: 'body', reportProgress?: boolean): Observable<Array<StudentInfoDTO>>;
    public getStudents(body: StudentsFilterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentInfoDTO>>>;
    public getStudents(body: StudentsFilterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentInfoDTO>>>;
    public getStudents(body: StudentsFilterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getStudents.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<StudentInfoDTO>>('post',`${this.basePath}/api/v1/user/students`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il profilo dell&#x27;utente.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/api/v1/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli utenti.
     * 
     * @param utente 
     * @param ruolo 
     * @param abilitato 
     * @param verificato 
     * @param numeroCiclo 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PagePlatformUserDTO>;
    public getUsersForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PagePlatformUserDTO>>;
    public getUsersForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PagePlatformUserDTO>>;
    public getUsersForm(utente?: string, ruolo?: string, abilitato?: boolean, verificato?: boolean, numeroCiclo?: number, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (utente !== undefined) {
            formParams = formParams.append('utente', <any>utente) as any || formParams;
        }
        if (ruolo !== undefined) {
            formParams = formParams.append('ruolo', <any>ruolo) as any || formParams;
        }
        if (abilitato !== undefined) {
            formParams = formParams.append('abilitato', <any>abilitato) as any || formParams;
        }
        if (verificato !== undefined) {
            formParams = formParams.append('verificato', <any>verificato) as any || formParams;
        }
        if (numeroCiclo !== undefined) {
            formParams = formParams.append('numeroCiclo', <any>numeroCiclo) as any || formParams;
        }

        return this.httpClient.request<PagePlatformUserDTO>('post',`${this.basePath}/api/v1/user/all`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli utenti con uno specifico ruolo in piattaforma.
     * 
     * @param ruoli 
     * @param idCicloDottorato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersByRoleForm(ruoli?: Array<string>, idCicloDottorato?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PlatformUser>>;
    public getUsersByRoleForm(ruoli?: Array<string>, idCicloDottorato?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PlatformUser>>>;
    public getUsersByRoleForm(ruoli?: Array<string>, idCicloDottorato?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PlatformUser>>>;
    public getUsersByRoleForm(ruoli?: Array<string>, idCicloDottorato?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (ruoli) {
            ruoli.forEach((element) => {
                // @ts-ignore
                formParams = formParams.append('ruoli', <any>element) || formParams;
            })
        }
        if (idCicloDottorato !== undefined) {
            formParams = formParams.append('idCicloDottorato', <any>idCicloDottorato) as any || formParams;
        }

        return this.httpClient.request<Array<PlatformUser>>('post',`${this.basePath}/api/v1/user/roles`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registra un nuovo utente.
     *
     * @param body Form di registrazione dell&#x27;utente da aggiungere.
     * @param X_recaptcha_token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(body: UserRegistrationForm, X_recaptcha_token: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public registerUser(body: UserRegistrationForm, X_recaptcha_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public registerUser(body: UserRegistrationForm, X_recaptcha_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public registerUser(body: UserRegistrationForm, X_recaptcha_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUser.');
        }

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling registerUser.');
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('post',`${this.basePath}/api/v1/user/registration`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un account Amministratore di registrare un nuovo utente.
     *
     * @param body Form di registrazione dell&#x27;utente da aggiungere.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUserFromAdmin(body: UserRegistrationForm, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public registerUserFromAdmin(body: UserRegistrationForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public registerUserFromAdmin(body: UserRegistrationForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public registerUserFromAdmin(body: UserRegistrationForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUserFromAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('post',`${this.basePath}/api/v1/user/registration-from-admin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Annulla la procedura di reset-password a partire dal token fornito tramite email.
     * Invocata da utente tramite link ricevuto via mail.
     * @param X_recaptcha_token Token reCAPTCHA
     * @param token Token fornito tramite mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetNotMe(X_recaptcha_token: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public resetNotMe(X_recaptcha_token: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public resetNotMe(X_recaptcha_token: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public resetNotMe(X_recaptcha_token: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling resetNotMe.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling resetNotMe.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/reset-not-me`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Richiede il reset della password dell&#x27;utente.
     *
     * @param email
     * @param X_recaptcha_token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUserPasswordForm(email: string, X_recaptcha_token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public resetUserPasswordForm(email: string, X_recaptcha_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public resetUserPasswordForm(email: string, X_recaptcha_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public resetUserPasswordForm(email: string, X_recaptcha_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling resetUserPassword.');
        }

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling resetUserPassword.');
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/reset-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Setta come ritirato un account studente.
     * 
     * @param userId 
     * @param retirementDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retireStudentForm(userId?: string, retirementDate?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public retireStudentForm(userId?: string, retirementDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public retireStudentForm(userId?: string, retirementDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public retireStudentForm(userId?: string, retirementDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (userId !== undefined) {
            formParams = formParams.append('userId', <any>userId) as any || formParams;
        }
        if (retirementDate !== undefined) {
            formParams = formParams.append('retirementDate', <any>retirementDate) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/retirement`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Setta la password di un utente registrato da un account Amministratore.
     * 
     * @param token 
     * @param password 
     * @param privacyPolicyConsent 
     * @param X_recaptcha_token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public setUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public setUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public setUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling setUserPassword.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling setUserPassword.');
        }

        if (privacyPolicyConsent === null || privacyPolicyConsent === undefined) {
            throw new Error('Required parameter privacyPolicyConsent was null or undefined when calling setUserPassword.');
        }

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling setUserPassword.');
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (privacyPolicyConsent !== undefined) {
            formParams = formParams.append('privacyPolicyConsent', <any>privacyPolicyConsent) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/set-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sblocca un utente a partire dal suo identificativo e ne invalida tutti gli eventuali token.
     * Invocata da utente amministratore.
     * @param user_id Identificativo dell&#x27;utente da sbloccare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlockUser(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public unlockUser(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public unlockUser(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public unlockUser(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling unlockUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('put',`${this.basePath}/api/v1/user/unlock/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sblocca un utente a partire dal token fornito tramite email ne invalida tutti gli eventuali token.
     * Invocata da utente tramite link ricevuto via mail.
     * @param token Token fornito tramite mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlockUserByToken(token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public unlockUserByToken(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public unlockUserByToken(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public unlockUserByToken(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling unlockUserByToken.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/unlock-token`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna la password dell&#x27;utente.
     * 
     * @param token 
     * @param password 
     * @param privacyPolicyConsent 
     * @param X_recaptcha_token Token reCAPTCHA
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public updateUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public updateUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public updateUserPasswordForm(token: string, password: string, privacyPolicyConsent: boolean, X_recaptcha_token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling updateUserPassword.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling updateUserPassword.');
        }

        if (privacyPolicyConsent === null || privacyPolicyConsent === undefined) {
            throw new Error('Required parameter privacyPolicyConsent was null or undefined when calling updateUserPassword.');
        }

        if (X_recaptcha_token === null || X_recaptcha_token === undefined) {
            throw new Error('Required parameter X_recaptcha_token was null or undefined when calling updateUserPassword.');
        }

        let headers = this.defaultHeaders;
        if (X_recaptcha_token !== undefined && X_recaptcha_token !== null) {
            headers = headers.set('X-recaptcha-token', String(X_recaptcha_token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }
        if (privacyPolicyConsent !== undefined) {
            formParams = formParams.append('privacyPolicyConsent', <any>privacyPolicyConsent) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/update-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna i campi del profilo dell&#x27;utente.
     * 
     * @param user_id 
     * @param nome 
     * @param cognome 
     * @param ssd 
     * @param dipartimento 
     * @param numeroCellulare 
     * @param privacyPolicyConsent 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserProfileForm(user_id: string, nome?: string, cognome?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, privacyPolicyConsent?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PlatformUser>;
    public updateUserProfileForm(user_id: string, nome?: string, cognome?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, privacyPolicyConsent?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlatformUser>>;
    public updateUserProfileForm(user_id: string, nome?: string, cognome?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, privacyPolicyConsent?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlatformUser>>;
    public updateUserProfileForm(user_id: string, nome?: string, cognome?: string, ssd?: string, dipartimento?: string, numeroCellulare?: string, privacyPolicyConsent?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling updateUserProfile.');
        }







        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nome !== undefined) {
            formParams = formParams.append('nome', <any>nome) as any || formParams;
        }
        if (cognome !== undefined) {
            formParams = formParams.append('cognome', <any>cognome) as any || formParams;
        }
        if (ssd !== undefined) {
            formParams = formParams.append('ssd', <any>ssd) as any || formParams;
        }
        if (dipartimento !== undefined) {
            formParams = formParams.append('dipartimento', <any>dipartimento) as any || formParams;
        }
        if (numeroCellulare !== undefined) {
            formParams = formParams.append('numeroCellulare', <any>numeroCellulare) as any || formParams;
        }
        if (privacyPolicyConsent !== undefined) {
            formParams = formParams.append('privacyPolicyConsent', <any>privacyPolicyConsent) as any || formParams;
        }

        return this.httpClient.request<PlatformUser>('put',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}/profile`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifica un nuovo account a partire dal token ricevuto via mail dopo la registrazione.
     *
     * @param token Token fornito tramite mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyAccount(token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public verifyAccount(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public verifyAccount(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public verifyAccount(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling verifyAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/verification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
