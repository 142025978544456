
export const environment = {
    name: 'dev',
    production: false,
    servicesUrl: '/dottorandi-backend',
    recaptcha: {
        siteKey: '6Ld7ZdkhAAAAABMgYYwCAAUxt4hO7CGK-KKGOObF',
    },
    show: false,
};
