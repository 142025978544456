import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {BooleanInput} from '@angular/cdk/coercion';
import {finalize, Subject, takeUntil} from 'rxjs';
import {LocalStorageService, RolesEnum} from "../../../shared/services/local-storage.service";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {TranslocoService} from "@ngneat/transloco";
import {get} from "lodash";
import {PathEnum} from "../../../app.routing";
import {AuthService, UsersService} from "../../../../api-clients/generated/services";

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    rules: RolesEnum[];
    showProfilo: boolean;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UsersService,
        private localStorageService: LocalStorageService,
        public fuseConfirmationService: FuseConfirmationService,
        private _authService: AuthService,
        private _translocoService: TranslocoService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        if (this.localStorageService.getUser()) {
            this.user = JSON.parse(this.localStorageService.getUser());
        }
        this.rules = this.localStorageService.getRules();
        console.log(this.rules);
        this.showProfilo = this.rules?.length > 0 && this.rules?.some(v => v === RolesEnum.ROLE_docente || v === RolesEnum.ROLE_esterno || v === RolesEnum.ROLE_personale_pta);
        console.log(this.showProfilo);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this.fuseConfirmationService.showLoader();
        this._authService.logoutUser().pipe(
            finalize(() => {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.localStorageService.cleanToken();
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'home.text_success_sign_out', null),
                    type: SnackbarTypes.Success,
                });
                this.fuseConfirmationService.hideLoader();
                this._router.navigate(['/' + PathEnum.SIGN_IN]);
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    goToProfiloUtente() {
        this._router.navigate(['/' + PathEnum.PROFILE]);
    }
}
