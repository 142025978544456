import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmationConfig} from '@fuse/services/confirmation/confirmation.types';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

            @screen md {
                @apply w-128;
            }

            .mat-dialog-container {
                padding: 0 !important;
            }

            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmationDialogComponent {
    /**
     * Constructor
     */
    constructor(public dialogRef: MatDialogRef<FuseConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig) {
    }

    onBackdropClose() {
        if (this.data?.onBackdrop?.function) {
            this.data.onBackdrop.function();
        }
        this.dialogRef.close();
    }

    confirm(func?: () => void) {
        if (!!func) {
            func()
        }
        this.dialogRef.close();
    }
}
