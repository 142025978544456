/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CampoConfigurabile } from '../model/campoConfigurabile';
import { CampoConfigurabileDto } from '../model/campoConfigurabileDto';
import { CicloDottorato } from '../model/cicloDottorato';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CampiConfigurabiliService {

    protected basePath = 'https://dottorandi.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiorna il campo configurabile del tipo &#x27;Anagrafica Studenti&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgAnagraficaStudenti.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgAnagraficaStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/anagrafica-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il campo configurabile del tipo &#x27;Periodo Esterno&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgPeriodoEsterno.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgPeriodoEsterno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/periodo-esterno`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il campo configurabile del tipo &#x27;Piano di Studi&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgPianoStudi.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgPianoStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/piano-studi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il campo configurabile del tipo &#x27;Tipologia Borsa&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgTipologiaBorsa.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgTipologiaBorsa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-borsa`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il campo configurabile del tipo &#x27;Tipologia Curricula&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgTipologiaCurricula.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgTipologiaCurricula.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-curricula`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il campo configurabile del tipo &#x27;Tipologia SSD&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiornaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public aggiornaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public aggiornaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiornaCampoCfgTipologiaSSD.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling aggiornaCampoCfgTipologiaSSD.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-ssd`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il valore di archiviazione per un campo configurabile, dato il suo identificativo.
     * 
     * @param campo_configurabile_id Identificativo del campo configurabile da archiviare. Non può essere nullo o vuoto.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato. Non può essere nullo o vuoto.
     * @param archive Valore di archiviazione del campo configurabile. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiviaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public archiviaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public archiviaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public archiviaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, archive: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campo_configurabile_id === null || campo_configurabile_id === undefined) {
            throw new Error('Required parameter campo_configurabile_id was null or undefined when calling archiviaCampoCfg.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling archiviaCampoCfg.');
        }

        if (archive === null || archive === undefined) {
            throw new Error('Required parameter archive was null or undefined when calling archiviaCampoCfg.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ciclo_dottorato_id !== undefined && ciclo_dottorato_id !== null) {
            queryParameters = queryParameters.set('ciclo_dottorato_id', <any>ciclo_dottorato_id);
        }
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(campo_configurabile_id))}/archiviazione`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il valore di archiviazione per un sottocampo configurabile, dato il suo identificativo.
     * 
     * @param option_id Identificativo della option configurabile da archiviare. Non può essere nullo o vuoto.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato. Non può essere nullo o vuoto.
     * @param archive Valore di archiviazione del campo configurabile. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiviaSottocampoCfg(option_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public archiviaSottocampoCfg(option_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public archiviaSottocampoCfg(option_id: string, ciclo_dottorato_id: string, archive: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public archiviaSottocampoCfg(option_id: string, ciclo_dottorato_id: string, archive: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (option_id === null || option_id === undefined) {
            throw new Error('Required parameter option_id was null or undefined when calling archiviaSottocampoCfg.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling archiviaSottocampoCfg.');
        }

        if (archive === null || archive === undefined) {
            throw new Error('Required parameter archive was null or undefined when calling archiviaSottocampoCfg.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ciclo_dottorato_id !== undefined && ciclo_dottorato_id !== null) {
            queryParameters = queryParameters.set('ciclo_dottorato_id', <any>ciclo_dottorato_id);
        }
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/campi-configurabili/options/${encodeURIComponent(String(option_id))}/archiviazione`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Anagrafica Studenti&#x27; all&#x27;interno del ciclo di dottorato specificato.
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgAnagraficaStudenti(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgAnagraficaStudenti.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgAnagraficaStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/anagrafica-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Periodo Esterno&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgPeriodoEsterno(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgPeriodoEsterno.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgPeriodoEsterno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/periodo-esterno`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Piano di Studi&#x27; all&#x27;interno del ciclo di dottorato specificato.
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgPianoStudi(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgPianoStudi.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgPianoStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/piano-studi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Tipologia Borsa&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgTipologiaBorsa(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgTipologiaBorsa.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgTipologiaBorsa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-borsa`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Tipologia Curricula&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgTipologiaCurricula(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgTipologiaCurricula.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgTipologiaCurricula.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-curricula`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo campo configurabile del tipo &#x27;Tipologia SSD&#x27; all&#x27;interno del ciclo di dottorato specificato
     * 
     * @param body Form di registrazione del campo configurabile da aggiungere.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public creaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public creaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public creaCampoCfgTipologiaSSD(body: CampoConfigurabileDto, ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling creaCampoCfgTipologiaSSD.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling creaCampoCfgTipologiaSSD.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CampoConfigurabile>('post',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-ssd`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Anagrafica Studenti&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoAnagraficaStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/anagrafica-studenti`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Periodo Esterno&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoPeriodoEsterno(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoPeriodoEsterno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/periodo-esterno`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Piano di Studi&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoPianoStudi(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoPianoStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/piano-studi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Tipologia Borsa&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoTipologiaBorsa(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoTipologiaBorsa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-borsa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Tipologia Curricula&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoTipologiaCurricula(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoTipologiaCurricula.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-curricula`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina la lista dei campi configurabili &#x27;Tipologia SSD&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteCampoTipologiaSSD(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling deleteCampoTipologiaSSD.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-ssd`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Anagrafica Studenti&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoAnagraficaStudenti(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoAnagraficaStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/anagrafica-studenti`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Periodo Esterno&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoPeriodoEsterno(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoPeriodoEsterno(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoPeriodoEsterno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/periodo-esterno`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Piano di Studi&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoPianoStudi(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoPianoStudi(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoPianoStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/piano-studi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Tipologia Borsa&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoTipologiaBorsa(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoTipologiaBorsa(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoTipologiaBorsa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-borsa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Tipologia Curricula&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoTipologiaCurricula(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoTipologiaCurricula(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoTipologiaCurricula.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-curricula`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ottieni la lista dei campi configurabili &#x27;Tipologia SSD&#x27; associati al ciclo di dottorato richiesto
     * 
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato da modificare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'body', reportProgress?: boolean): Observable<CampoConfigurabile>;
    public getCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampoConfigurabile>>;
    public getCampoTipologiaSSD(ciclo_dottorato_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampoConfigurabile>>;
    public getCampoTipologiaSSD(ciclo_dottorato_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling getCampoTipologiaSSD.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CampoConfigurabile>('get',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(ciclo_dottorato_id))}/tipologia-ssd`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imposta il valore di visualizzazione per un campo configurabile, dato il suo identificativo.
     * 
     * @param campo_configurabile_id Identificativo del campo configurabile da aggiornare. Non può essere nullo o vuoto.
     * @param ciclo_dottorato_id Identificativo del ciclo di dottorato. Non può essere nullo o vuoto.
     * @param show Valore di visualizzazione del campo configurabile. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public visualizzaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, show: boolean, observe?: 'body', reportProgress?: boolean): Observable<CicloDottorato>;
    public visualizzaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, show: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloDottorato>>;
    public visualizzaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, show: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloDottorato>>;
    public visualizzaCampoCfg(campo_configurabile_id: string, ciclo_dottorato_id: string, show: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campo_configurabile_id === null || campo_configurabile_id === undefined) {
            throw new Error('Required parameter campo_configurabile_id was null or undefined when calling visualizzaCampoCfg.');
        }

        if (ciclo_dottorato_id === null || ciclo_dottorato_id === undefined) {
            throw new Error('Required parameter ciclo_dottorato_id was null or undefined when calling visualizzaCampoCfg.');
        }

        if (show === null || show === undefined) {
            throw new Error('Required parameter show was null or undefined when calling visualizzaCampoCfg.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ciclo_dottorato_id !== undefined && ciclo_dottorato_id !== null) {
            queryParameters = queryParameters.set('ciclo_dottorato_id', <any>ciclo_dottorato_id);
        }
        if (show !== undefined && show !== null) {
            queryParameters = queryParameters.set('show', <any>show);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloDottorato>('put',`${this.basePath}/api/v1/campi-configurabili/${encodeURIComponent(String(campo_configurabile_id))}/visualizzazione`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
