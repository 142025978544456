import {NgModule} from '@angular/core';
import {LayoutComponent} from 'app/layout/layout.component';
import {EmptyLayoutModule} from 'app/layout/layouts/empty/empty.module';
import {CenteredLayoutModule} from 'app/layout/layouts/horizontal/centered/centered.module';
import {EnterpriseLayoutModule} from 'app/layout/layouts/horizontal/enterprise/enterprise.module';
import {MaterialLayoutModule} from 'app/layout/layouts/horizontal/material/material.module';
import {ModernLayoutModule} from 'app/layout/layouts/horizontal/modern/modern.module';
import {ClassicLayoutModule} from 'app/layout/layouts/vertical/classic/classic.module';
import {ClassyLayoutModule} from 'app/layout/layouts/vertical/classy/classy.module';
import {CompactLayoutModule} from 'app/layout/layouts/vertical/compact/compact.module';
import {DenseLayoutModule} from 'app/layout/layouts/vertical/dense/dense.module';
import {FuturisticLayoutModule} from 'app/layout/layouts/vertical/futuristic/futuristic.module';
import {ThinLayoutModule} from 'app/layout/layouts/vertical/thin/thin.module';
import {SettingsModule} from 'app/layout/common/settings/settings.module';
import {SharedModule} from 'app/shared/shared.module';
import {SpinnerModule} from "../shared/components/spinner/spinner.module";
import {CardedLeftSidebarPageScrollComponent} from "./page-layouts/page-scroll/left-sidebar.component";
import {CdkScrollableModule} from "@angular/cdk/scrolling";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {FuseNavigationModule} from "../../@fuse/components/navigation";
import {TranslocoModule} from "@ngneat/transloco";
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {FlexModule} from "@angular/flex-layout";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";


const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule,


    CdkScrollableModule,
    MatSidenavModule,
    MatIconModule,
    RouterModule
];

@NgModule({
    declarations: [
        LayoutComponent,
        CardedLeftSidebarPageScrollComponent,
        BreadcrumbComponent,
    ],
    imports: [
        SharedModule,
        SettingsModule,
        ...layoutModules,
        SpinnerModule,
        FuseNavigationModule,
        TranslocoModule,
        FlexModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule
    ],
    exports: [
        LayoutComponent,
        CardedLeftSidebarPageScrollComponent,
        ...layoutModules
    ]
})
export class LayoutModule {
}
