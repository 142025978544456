import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-dialog-wrap',
    templateUrl: './dialog-wrap.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DialogWrapComponent {

    @Input() data: DialogWrapI

}
export interface DialogWrapI {
    title?: string;
    message?: string;
    icon?: {
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
}
