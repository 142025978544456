export * from './anagraficaStudente.service';
import { AnagraficaStudenteService } from './anagraficaStudente.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './budgetStudente.service';
import { BudgetStudenteService } from './budgetStudente.service';
export * from './campiConfigurabili.service';
import { CampiConfigurabiliService } from './campiConfigurabili.service';
export * from './cicliDottorato.service';
import { CicliDottoratoService } from './cicliDottorato.service';
export * from './dateTime.service';
import { DateTimeService } from './dateTime.service';
export * from './emailSender.service';
import { EmailSenderService } from './emailSender.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './import.service';
import { ImportService } from './import.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './offerteFormative.service';
import { OfferteFormativeService } from './offerteFormative.service';
export * from './pianiStudi.service';
import { PianiStudiService } from './pianiStudi.service';
export * from './registroAttivita.service';
import { RegistroAttivitaService } from './registroAttivita.service';
export * from './tesiStudente.service';
import { TesiStudenteService } from './tesiStudente.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AnagraficaStudenteService, AuthService, BudgetStudenteService, CampiConfigurabiliService, CicliDottoratoService, DateTimeService, EmailSenderService, ExportService, ImportService, MediaService, OfferteFormativeService, PianiStudiService, RegistroAttivitaService, TesiStudenteService, UsersService];
