import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslocoService} from '@ngneat/transloco';


@Injectable()
export class MatPaginatorI18n extends MatPaginatorIntl {
    public constructor(private translocoService: TranslocoService) {
        super();

        this.translocoService.langChanges$.subscribe(() => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex: number = page * pageSize;
        const endIndex: number = startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };

    public getAndInitTranslations(): void {

        this.translocoService.selectTranslate('custom_table.last').subscribe((translation: string) => {
            this.lastPageLabel = translation;
        });
        this.translocoService.selectTranslate('custom_table.first').subscribe((translation: string) => {
            this.firstPageLabel = translation;
        });
        this.translocoService.selectTranslate('custom_table.previous').subscribe((translation: string) => {
            this.previousPageLabel = translation;
        });
        this.translocoService.selectTranslate('custom_table.next').subscribe((translation: string) => {
            this.nextPageLabel = translation;
        });
        this.translocoService.selectTranslate('custom_table.page').subscribe((translation: string) => {
            this.itemsPerPageLabel = translation;
            this.changes.next();
        });
    }
}
