import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogWrapI} from "../../components/dialog-wrap/dialog-wrap.component";

export interface DialogPrivacyI extends DialogWrapI {
    isPrivacy: boolean;
}

@Component({
    selector: 'app-dialog-privacy',
    templateUrl: './dialog-privacy.component.html',
    styleUrls: ['./dialog-privacy.component.scss']
})
export class DialogPrivacyComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogPrivacyI,
                public dialogRef: MatDialogRef<DialogPrivacyComponent>) {
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
