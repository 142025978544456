import {MatSelectModule} from '@angular/material/select';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RemoveUnderscoresPipe} from "./pipes/remove-underscores.pipe";
import {DataFormatPipe} from "./pipes/data-format.pipe";
import {DateToUtcPipe} from "./pipes/dateToUtc.pipe";
import {DynamicPipe} from './pipes/dynamic.pipe';
import {ReplaceBooleanPipe} from "./pipes/replace-boolean.pipe";
import {ReplaceEnumToStringPipe} from "./pipes/replace-enum.pipe";
import { CurrencyEuroPipe } from './pipes/currency-euro.pipe';
import {ValoreTypePipe} from "./pipes/valore-type.pipe";

@NgModule({
    declarations: [
        RemoveUnderscoresPipe,
        DataFormatPipe,
        DateToUtcPipe,
        DynamicPipe,
        ReplaceBooleanPipe,
        ReplaceEnumToStringPipe,
        CurrencyEuroPipe,
        ValoreTypePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RemoveUnderscoresPipe,
        DataFormatPipe,
        DateToUtcPipe,
        DynamicPipe,
        ReplaceEnumToStringPipe,
        ReplaceBooleanPipe,
        CurrencyEuroPipe,
        ValoreTypePipe
    ]
})
export class SharedModule {
}
