import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    AnagraficaStudenteDto,
    AnnoType,
    AttivitaExtraDTO, AttivitaRetribuita,
    CampoCfgSelezionatoDto,
    CampoConfigurabile,
    CicloDottorato,
    CorsoInPianoStudiDTO,
    OptionSottocampo,
    PermissionType,
    PianoStudiDTO,
    SchedaTesiStudente,
    ShownPageType,
    ValoreType
} from "../../../../../api-clients/generated/services";
import {emailRegex, urlRegex} from "../../../../shared/constants/regex";
import {cloneDeep, compact} from "lodash";
import {isArray} from "lodash-es";
import {CustomValidators} from "../../../../shared/validators/custom-validators";

@Injectable({
    providedIn: 'root'
})
export class StudentDetailService {
    ruoli: PermissionType[] = [];

    constructor() {
    }

    createForm(_formBuilder: FormBuilder, cicloDottorato: CicloDottorato, studente: AnagraficaStudenteDto): FormGroup {
        const control = new FormControl()
        return _formBuilder.group({
            id: studente.id_utente,
            isFourYearProgramme: studente.isFourYearProgramme,
            id_anagrafica: studente.id_anagrafica,
            nome: studente?.nome,
            cognome: studente?.cognome,
            numero_cellulare_studente: [studente?.numero_cellulare_studente, Validators.minLength(6)],
            url_foto: studente.url_foto,
            matricola: studente?.matricola,
            email_studente: [{value: studente?.email_studente, disabled: true}],
            ciclo_iscrizione: [{value: studente?.ciclo_iscrizione, disabled: true}],
            id_supervisore: studente?.id_supervisore,
            ssd_supervisore: studente?.ssd_supervisore,
            ateneoProvenienza: studente?.ateneoProvenienza,
            dipartimento_supervisore: studente?.dipartimento_supervisore,
            email_supervisore: [studente?.email_supervisore, Validators.pattern(new RegExp(emailRegex))],
            numero_cellulare_supervisore: [studente?.numero_cellulare_supervisore, Validators.minLength(6)],
            id_co_supervisore: studente?.id_co_supervisore,
            ssd_co_supervisore: studente?.ssd_co_supervisore,
            dipartimento_co_supervisore: studente?.dipartimento_co_supervisore,
            email_co_supervisore: [studente?.email_co_supervisore, Validators.pattern(new RegExp(emailRegex))],
            numero_cellulare_co_supervisore: [studente?.numero_cellulare_co_supervisore, Validators.minLength(6)],
            note: studente?.note,
            periodoSospensioneProrogaA: studente?.periodoSospensioneProrogaA,
            periodoSospensioneProrogaDa: studente?.periodoSospensioneProrogaDa,
            pubblicazioni: [studente?.pubblicazioni, Validators.pattern(new RegExp(urlRegex))],
            relazioni_annuali_allegato_1: studente?.relazioni_annuali_allegato_1,
            relazioni_annuali_allegato_2: studente?.relazioni_annuali_allegato_2,
            relazioni_annuali_allegato_3: studente?.relazioni_annuali_allegato_3,
            registri_annuali_allegato_4: studente?.registri_annuali_allegato_4,
            registri_annuali_allegato_5: studente?.registri_annuali_allegato_5,
            registri_annuali_allegato_6: studente?.registri_annuali_allegato_6,
            relazioni_annuali_allegato_7: studente.relazioni_annuali_allegato_7,
            registri_annuali_allegato_8: studente.registri_annuali_allegato_8,
            isRetired: studente.isRetired,
            dataRitiro: studente.dataRitiro,
            dataIscrizione: studente.dataIscrizione,
            curriculum: cicloDottorato?.tipologiaCurricula?.id ? this.getCurriculum(studente, cicloDottorato, _formBuilder) : null,
            campoConfigurabileAnagraficaStudente: cicloDottorato?.campoConfigurabileAnagraficaStudente?.id ? this.getCampoConfigurabileStudente(studente, cicloDottorato, _formBuilder) : null,
            tipologiaBorsa: cicloDottorato?.tipologiaBorsa?.id ? this.tipologiaBorsa(studente, cicloDottorato, _formBuilder) : null,
            periodoEsterno: cicloDottorato?.periodoEsterno?.id ? this.getPeriodoEsterno(_formBuilder, cicloDottorato, studente) : null,
            attivitaRetribuite: this.getAttivitaRetribuite(_formBuilder, studente),
            updated_at: studente?.updated_at,
        })
    }

    private getPeriodoEsterno(_formBuilder: FormBuilder, cicloDottorato: CicloDottorato, studente: AnagraficaStudenteDto) {
        const periodiEsternoStudente = studente?.campiCfgSelezionati?.filter(campo => campo?.idCampoConfigurabile === cicloDottorato?.periodoEsterno?.id);
        let formArray = new FormArray([]);
        if (periodiEsternoStudente?.length > 0) {
            periodiEsternoStudente.forEach(periodo => {
                formArray.push(this.periodFormGroup(_formBuilder, cicloDottorato, periodo, studente), {emitEvent: false});
            });
        }
        return formArray;
    }

    periodFormGroup(_formBuilder: FormBuilder, cicloDottorato: CicloDottorato, periodo?: CampoCfgSelezionatoDto, studente?: AnagraficaStudenteDto) {
        let sottocampi = []
        if (periodo) {
            sottocampi = studente?.campiCfgSelezionati?.filter(campo => campo?.idParentCampoCfgSelezionato === periodo?.id)
        }
        return _formBuilder.group({
            id: periodo?.id,
            dataDa: periodo?.dataDa,
            dataA: periodo?.dataA,
            valore: periodo?.valore,
            idOptionSelezionata: periodo?.idOptionSelezionata,
            idCampoConfigurabile: cicloDottorato?.periodoEsterno?.id,
            isAllegato: cicloDottorato.periodoEsterno?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => cicloDottorato.periodoEsterno.ruoloModifica.includes(ruolo))),
            sottocampiSelezionati: sottocampi?.length > 0 ? this.createArraySottocampi(cicloDottorato?.periodoEsterno?.sottocampi, _formBuilder, sottocampi) : this.createArraySottocampi(cicloDottorato?.periodoEsterno?.sottocampi, _formBuilder)
        });
    }

    private createArraySottocampi(sottocampi: Array<CampoConfigurabile>, _formBuilder: FormBuilder, sottocampiPeriodo?: CampoCfgSelezionatoDto[]): FormArray {
        if (sottocampi?.length > 0) {
            let formArray = new FormArray([])
            sottocampi.forEach(sottocampo => {
                const valore = sottocampiPeriodo?.find(periodo => periodo?.idCampoConfigurabile == sottocampo?.id)
                formArray.push(_formBuilder.group({
                    id: valore?.id,
                    dataDa: valore?.dataDa,
                    dataA: valore?.dataA,
                    valore: valore?.valore,
                    idOptionSelezionata: valore?.idOptionSelezionata,
                    idCampoConfigurabile: sottocampo?.id,
                    isAllegato: sottocampo.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => sottocampo.ruoloModifica.includes(ruolo)))
                }), {emitEvent: false})
            })
            return formArray;
        } else {
            return null
        }
    }

    private createArrayOptionsSottocampi(options: Array<OptionSottocampo>, _formBuilder: FormBuilder, studente: AnagraficaStudenteDto, pianoDiStudi?: PianoStudiDTO): FormArray {
        if (options?.length > 0) {
            let formArray = new FormArray([])
            options.forEach(option => {
                option.sottocampi.forEach(sottocampo => {
                    const valore = (pianoDiStudi || studente)?.campiCfgSelezionati?.find(campo => campo.idCampoConfigurabile == sottocampo?.id)
                    formArray.push(_formBuilder.group({
                        id: valore?.id,
                        dataDa: valore?.dataDa,
                        dataA: valore?.dataA,
                        idOptionSelezionata: valore?.idOptionSelezionata,
                        valore: valore?.valore,
                        idCampoConfigurabile: sottocampo?.id,
                        idOpzioneParent: option?.id,
                        isAllegato: sottocampo?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => sottocampo?.ruoloModifica?.includes(ruolo))),

                    }))
                })
            })
            return formArray;
        } else {
            return null
        }
    }

    onlyShowTipePage(cicloDottorato: CicloDottorato, shownPageType: ShownPageType): CicloDottorato {
        return {
            ...cicloDottorato,
            tipologiaBorsa: {
                ...cicloDottorato.tipologiaBorsa,
                options: cicloDottorato?.tipologiaBorsa?.options.map(option => {
                    return {
                        ...option,
                        sottocampi: option.sottocampi.filter(sottocampo => sottocampo.shownIn.includes(shownPageType))
                    }
                })
            },
            periodoEsterno: {
                ...cicloDottorato.periodoEsterno,
                sottocampi: cicloDottorato.periodoEsterno?.sottocampi?.filter(sottocampo => sottocampo.shownIn.includes(shownPageType))
            }
        }
    }

    formToAnagrafica(anagrafica: FormAnagrafica): AnagraficaStudenteDto {
        let tipologiaBorsaToCampiCfgSelezionati: CampoCfgSelezionatoDto[] = []
        if (anagrafica?.tipologiaBorsa?.idCampoConfigurabile) {
            const tipologieBorsaSottocampi = cloneDeep(anagrafica.tipologiaBorsa?.sottocampiSelezionati?.filter(sottocampo => sottocampo?.idOpzioneParent === anagrafica.tipologiaBorsa.idOptionSelezionata)) || []
            delete anagrafica?.tipologiaBorsa?.sottocampiSelezionati
            tipologiaBorsaToCampiCfgSelezionati = compact([{...anagrafica.tipologiaBorsa}, ...tipologieBorsaSottocampi]) as unknown as CampoCfgSelezionatoDto[]
        }
        let tipologiaCurriculumaToCampiCfgSelezionati: CampoCfgSelezionatoDto[] = []
        if (anagrafica?.curriculum?.idCampoConfigurabile) {
            const tipologieSottocampi = cloneDeep(anagrafica.curriculum.sottocampiSelezionati) || []
            delete anagrafica?.curriculum?.sottocampiSelezionati
            tipologiaCurriculumaToCampiCfgSelezionati = compact([{...anagrafica.curriculum}, ...tipologieSottocampi]) as unknown as CampoCfgSelezionatoDto[]
        }


        const campiCfgSelezionati = compact([...tipologiaCurriculumaToCampiCfgSelezionati,
            ...tipologiaBorsaToCampiCfgSelezionati,
            ...anagrafica?.periodoEsterno?.map(periodo => ({
                ...periodo,
                sottocampiSelezionati: periodo?.sottocampiSelezionati?.filter(v => v.idOptionSelezionata || v.valore || v.dataA || v.dataDa || v.isAllegato)
            })) || [],
            anagrafica?.campoConfigurabileAnagraficaStudente || []]) as (CampoCfgSelezionatoDto & { isAllegato?: boolean })[]
        delete anagrafica.periodoEsterno;
        delete anagrafica.campoConfigurabileAnagraficaStudente;
        delete anagrafica.tipologiaBorsa;
        delete anagrafica.curriculum;
        return {
            ...anagrafica,
            attivitaRetribuite: anagrafica.attivitaRetribuite?.length > 0 ? anagrafica.attivitaRetribuite : undefined,
            campiCfgSelezionati: campiCfgSelezionati?.filter(v => v.idOptionSelezionata || v.valore || v.dataA || v.dataDa || v.isAllegato)
        }
    }

    private getCurriculum(studente: AnagraficaStudenteDto, cicloDottorato: CicloDottorato, _formBuilder: FormBuilder) {
        const curriculum = studente.campiCfgSelezionati.find(campo => campo.idCampoConfigurabile == cicloDottorato?.tipologiaCurricula?.id)

        return _formBuilder.group({
            id: curriculum?.id,
            dataDa: curriculum?.dataDa,
            dataA: curriculum?.dataA,
            valore: curriculum?.valore,
            idOptionSelezionata: curriculum?.idOptionSelezionata,
            idCampoConfigurabile: cicloDottorato?.tipologiaCurricula?.id,
            isAllegato: cicloDottorato.tipologiaCurricula?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => cicloDottorato.tipologiaCurricula?.ruoloModifica?.includes(ruolo))),

        })
    }

    private getCampoConfigurabileStudente(studente: AnagraficaStudenteDto, cicloDottorato: CicloDottorato, _formBuilder: FormBuilder) {
        const campo = studente?.campiCfgSelezionati?.find(campo => campo.idCampoConfigurabile == cicloDottorato?.campoConfigurabileAnagraficaStudente?.id)
        return _formBuilder.group({
            id: campo?.id,
            dataDa: campo?.dataDa,
            dataA: campo?.dataA,
            idOptionSelezionata: campo?.idOptionSelezionata,
            type: cicloDottorato?.campoConfigurabileAnagraficaStudente?.type,
            valore: campo?.valore,
            isAllegato: cicloDottorato.campoConfigurabileAnagraficaStudente?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => cicloDottorato.campoConfigurabileAnagraficaStudente?.ruoloModifica?.includes(ruolo))),

            idCampoConfigurabile: cicloDottorato?.campoConfigurabileAnagraficaStudente?.id
        })
    }


    private getCampoConfigurabilePianoDiStudi(pianoStudiDTO: PianoStudiDTO, cicloDottorato: CicloDottorato, _formBuilder: FormBuilder) {
        const campo = pianoStudiDTO?.campiCfgSelezionati?.find(campo => campo.idCampoConfigurabile == cicloDottorato?.campoConfigurabilePianoStudi?.id)
        return _formBuilder.group({
            id: campo?.id,
            dataDa: campo?.dataDa,
            dataA: campo?.dataA,
            idOptionSelezionata: campo?.idOptionSelezionata,
            type: cicloDottorato?.campoConfigurabilePianoStudi?.type,
            valore: campo?.valore,
            isAllegato: cicloDottorato.campoConfigurabilePianoStudi?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => cicloDottorato.campoConfigurabilePianoStudi?.ruoloModifica?.includes(ruolo))),
            idCampoConfigurabile: cicloDottorato?.campoConfigurabilePianoStudi?.id
        })
    }

    private tipologiaBorsa(studente: AnagraficaStudenteDto, cicloDottorato: CicloDottorato, _formBuilder: FormBuilder, pianoDiStudi?: PianoStudiDTO) {
        const tipologiaBorsa = studente?.campiCfgSelezionati?.find(campo => campo.idCampoConfigurabile == cicloDottorato?.tipologiaBorsa?.id)
        return _formBuilder.group({
            id: tipologiaBorsa?.id,
            dataDa: tipologiaBorsa?.dataDa,
            dataA: tipologiaBorsa?.dataA,
            valore: tipologiaBorsa?.valore,
            idOptionSelezionata: tipologiaBorsa?.idOptionSelezionata,
            idCampoConfigurabile: cicloDottorato?.tipologiaBorsa?.id,
            isParent: true,
            isAllegato: cicloDottorato.tipologiaBorsa?.type === ValoreType.ALLEGATO && (this.ruoli.includes(PermissionType.ADMIN) || this.ruoli.some(ruolo => cicloDottorato.tipologiaBorsa.ruoloModifica.includes(ruolo))),
            sottocampiSelezionati: this.createArrayOptionsSottocampi(cicloDottorato?.tipologiaBorsa?.options, _formBuilder, studente, pianoDiStudi)
        })
    }


    createFormStudyPlan(_formBuilder: FormBuilder, cicloDottorato: CicloDottorato, studente: AnagraficaStudenteDto, pianoDiStudi: PianoStudiDTO): FormGroup {
        return _formBuilder.group({
            tipologiaBorsa: cicloDottorato?.tipologiaBorsa?.id ? this.tipologiaBorsa(studente, cicloDottorato, _formBuilder, pianoDiStudi) : null,
            campoConfigurabilePianoStudi: cicloDottorato?.campoConfigurabilePianoStudi?.id ? this.getCampoConfigurabilePianoDiStudi(pianoDiStudi, cicloDottorato, _formBuilder) : null,
            updated_at: studente?.updated_at,
            dataAutorizzazioneSupervisore: (pianoDiStudi?.approvazioneSupervisore?.nominativoApprovatore || '') + ' - ' + (pianoDiStudi?.approvazioneSupervisore?.dataAutorizzazione || ''),
            dataAutorizzazioneCoordinatore: (pianoDiStudi?.approvazioneCoordinatore?.nominativoApprovatore || '') + ' - ' + (pianoDiStudi?.approvazioneCoordinatore?.dataAutorizzazione || ''),
            allegato_1: pianoDiStudi?.allegato_1,
            allegato_2: pianoDiStudi?.allegato_2,
            allegato_3: pianoDiStudi?.allegato_3,
            allegato_4: pianoDiStudi?.allegato_4,
            corsi: _formBuilder.array(pianoDiStudi?.corsi.map(corso => {
                return _formBuilder.group({
                    idCorso: corso.idCorso,
                    annoInsegnamentoScelto: corso.annoInsegnamentoScelto,
                })
            })),
            attivitaExtra: _formBuilder.array(pianoDiStudi?.attivitaExtra || []),
            corsiPresentati: pianoDiStudi?.corsiPresentati ? _formBuilder.array(pianoDiStudi?.corsiPresentati || []) : null,
            campiCfgSelezionati: []
        });

    }


    getBody(form: FormGroup, corsi: Array<CorsoInPianoStudiDTO>, anno: AnnoType, attivitaExtra?: AttivitaExtraDTO) {
        let body = form.getRawValue() as PianoStudiDTO & {
            tipologiaBorsa: any,
            dataAutorizzazioneSupervisore: any,
            dataAutorizzazioneCoordinatore: any
            campoConfigurabilePianoStudi: any,
        };

        // gestione corsi
        if (corsi && anno) {
            const newCorsi = form.get('corsi').getRawValue().filter(corso => corso.annoInsegnamentoScelto !== anno)
            newCorsi.push(...corsi)
            body = {
                ...body,
                corsi: newCorsi
            }
        }

        // gestione tipologia
        let tipologiaBorsaToCampiCfgSelezionati: CampoCfgSelezionatoDto[] = []
        if (body?.tipologiaBorsa?.idCampoConfigurabile) {
            const tipologieBorsaSottocampi = cloneDeep(body?.tipologiaBorsa?.sottocampiSelezionati) || []
            delete body?.tipologiaBorsa?.sottocampiSelezionati
            tipologiaBorsaToCampiCfgSelezionati = compact([{...body?.tipologiaBorsa}, ...tipologieBorsaSottocampi]) as unknown as CampoCfgSelezionatoDto[]
        }

        // gestione attività
        if (attivitaExtra) {
            if (attivitaExtra.id) {
                const index = body.attivitaExtra.findIndex(attivita => attivita.id === attivitaExtra.id)
                if (index > -1) {
                    body.attivitaExtra[index] = attivitaExtra;
                }
            } else {
                if (isArray(body.attivitaExtra)) {
                    body.attivitaExtra.push(attivitaExtra)
                } else {
                    body.attivitaExtra = [attivitaExtra]
                }
            }
        }

        const campiCfgSelezionati = compact([
            ...tipologiaBorsaToCampiCfgSelezionati,
            body?.campoConfigurabilePianoStudi || null,
        ]) as (CampoCfgSelezionatoDto & { isAllegato?: boolean, isParent?: boolean })[]

        delete body.tipologiaBorsa;
        delete body.tipologiaBorsa;
        delete body.dataAutorizzazioneCoordinatore;
        delete body.dataAutorizzazioneSupervisore
        return {
            ...body,
            campiCfgSelezionati: campiCfgSelezionati?.filter(v => (!v.isParent && (v.idOptionSelezionata || v.valore || v.dataA || v.dataDa || v.isAllegato)))
        };
    }

    createFormFinalExam(schedaTesi: SchedaTesiStudente, fb: FormBuilder): FormGroup {
        return fb.group({
            id: schedaTesi.id,
            updatedAt: schedaTesi.updatedAt,
            titolo: schedaTesi.titolo,
            ateneo: schedaTesi.ateneo,
            dataConsegnaPrevista: schedaTesi.dataConsegnaPrevista,
            dataConsegnaEffettiva: schedaTesi.dataConsegnaEffettiva,
            dataFineRevisione: schedaTesi.dataFineRevisione,
            idRevisoreUno: schedaTesi?.revisoreUno?.id,
            emailRevisoreUno: schedaTesi?.revisoreUno?.email,
            idRevisoreDue: schedaTesi?.revisoreDue?.id,
            emailRevisoreDue: schedaTesi?.revisoreDue?.email,
            dataDeadlineRevisione: schedaTesi.dataDeadlineRevisione,
            titoloDottoreEuropeo: schedaTesi.titoloDottoreEuropeo,
            checkPlagiarism: schedaTesi.checkPlagiarism,
            lingua: schedaTesi.lingua,
            altraLinguaScelta: schedaTesi.altraLinguaScelta,
            dataAutorizzazione: schedaTesi.dataAutorizzazione,
            dataRinvioEsameFinale: schedaTesi.dataRinvioEsameFinale,
            dataAmmissioneEsameFinale: schedaTesi.dataAmmissioneEsameFinale,
            dataEsameFinale: schedaTesi.dataEsameFinale,
            allegatoRelazioneFinale: schedaTesi.allegatoRelazioneFinale,
            allegatoAbstractEstesoInglese: schedaTesi.allegatoAbstractEstesoInglese,
            allegatoTesiSottomessa: schedaTesi.allegatoTesiSottomessa,
            allegatoTesiDefinitiva: schedaTesi.allegatoTesiDefinitiva,
            allegatoSchedaRevisoreUno: schedaTesi.allegatoSchedaRevisoreUno,
            allegatoSchedaRevisoreDue: schedaTesi.allegatoSchedaRevisoreDue,
            allegatoAccordoCoTutela: schedaTesi.allegatoAccordoCoTutela,
            allegatoCertificatoAntiplagio: schedaTesi.allegatoCertificatoAntiplagio
        })
    }

    private getAttivitaRetribuite(_formBuilder: FormBuilder, studente: AnagraficaStudenteDto) {
        let formArray = new FormArray([]);
        if (studente.attivitaRetribuite?.length > 0) {
            studente.attivitaRetribuite.forEach(attivita => {
                formArray.push(this.attivitaRetribuiteFormGroup(_formBuilder, attivita), {emitEvent: false});
            });
        }

        return formArray;
    }

    attivitaRetribuiteFormGroup(_formBuilder: FormBuilder, attivita?: AttivitaRetribuita) {
        return _formBuilder.group({
            id: attivita?.id,
            dataDelibera: attivita?.dataDelibera,
            allegatoDelibera: attivita?.allegatoDelibera,
            dataFine: [attivita?.dataFine, Validators.required],
            dataInizio: [attivita?.dataInizio,  Validators.required],
            updatedAt: attivita?.updatedAt,
        }, {validators: CustomValidators.dataAMaggioreDiDataA('dataInizio', 'dataFine', 'DD/MM/YYYY')});
    }
}


export interface CampoI {
    id: string,
    dataDa: string,
    dataA: string,
    valore: string | number,
    idCampoConfigurabile: string;
    sottocampiSelezionati: CampoI[],
    isAllegato?: boolean
    idOptionSelezionata: string;
}


export interface FormAnagrafica {
    nome?: string;
    cognome?: string;
    numero_cellulare_studente?: string;
    id_supervisore?: string;
    ssd_supervisore?: string;
    dipartimento_supervisore?: string;
    email_supervisore?: string;
    numero_cellulare_supervisore?: string;
    co_supervisore?: string;
    ssd_co_supervisore?: string;
    dipartimento_co_supervisore?: string;
    email_co_supervisore?: string;
    numero_cellulare_co_supervisore?: string;
    note?: string;
    curriculum?: CampoConfigurabileStudente;
    campoConfigurabileAnagraficaStudente?: CampoConfigurabileStudente;
    tipologiaBorsa?: CampoConfigurabileStudente;
    periodoEsterno?: CampoConfigurabileStudente[];
    attivitaRetribuite?: Array<AttivitaRetribuita>;
}

export interface CampoConfigurabileStudente {
    id?: null;
    dataDa?: Date | null;
    dataA?: Date | null;
    valore?: null | string;
    idCampoConfigurabile?: string;
    type?: ValoreType;
    sottocampiSelezionati?: CampoConfigurabileStudente[];
    isAllegato?: boolean
    idOptionSelezionata: string;
    idOpzioneParent?: string;

}
