import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    Observable,
    switchMap,
    take,
    throwError,
    timer,
    of
} from 'rxjs';
import {LocalStorageService} from '../services/local-storage.service';
import {Router} from '@angular/router';
import {TranslocoService} from "@ngneat/transloco";
import {filter, mergeMap} from "rxjs/operators";
import {AuthService, GrantType} from "../../../api-clients/generated/services";
import {PathEnum} from "../../app.routing";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {SnackbarTypes} from "../../../@fuse/services/confirmation/snackbar/snackbar.component";


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isTokenRefreshing: BehaviorSubject<boolean | undefined> | undefined;
    private errorRefresh: string;

    /**
     * Constructor
     */
    constructor(private localStorageService: LocalStorageService,
                private translocoService: TranslocoService,
                private authControllerService: AuthService,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router) {
        this.translocoService.selectTranslate('common.error_refresh_token').subscribe(value => this.errorRefresh = value)
    }

    /**
     * Intercept
     *
     * @param httpRequest
     * @param next
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let authReq = httpRequest.clone({headers: httpRequest.headers.append('Accept-Language', this.translocoService.getActiveLang())});

        if (authReq.url.includes('login') || authReq.url.includes('token')) {
            console.log('Intercettata chiamata di login o refresh token, non utilizzo interceptor');
            return next.handle(authReq);
        }

        if (httpRequest.method !== 'OPTIONS') {
            return this.handleRequest(authReq, next);
        } else {
            return next.handle(authReq);
        }
    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (!!this.isTokenRefreshing) {
                            console.log('isTokenRefreshing eiste');
                            return this.isTokenRefreshing.pipe(
                                filter((value) => value !== undefined),
                                take(1),
                                switchMap(
                                    (result) => {
                                        if (result) {
                                            console.log('Chiamata handlerequest 1');
                                            return timer(500).pipe(
                                                switchMap(() => this.handleRequest(request.clone({
                                                    setHeaders: {
                                                        Authorization: 'Bearer ' + this.localStorageService.getAccessToken(),
                                                    },
                                                }), next)));
                                        } else {
                                            return of(null);
                                        }
                                    }
                                )
                            );
                        } else {
                            console.log('isTokenRefreshing non esiste');
                            this.isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);
                            return this.authControllerService.refreshTokenForm(
                                this.localStorageService.getRefreshToken() ? this.localStorageService.getRefreshToken()! : '',
                                (!!this.localStorageService.getEmail() ? this.localStorageService.getEmail() : ''),
                                GrantType.REFRESHTOKEN).pipe(
                                distinctUntilChanged(),
                                take(1),
                                mergeMap((result) => {
                                    this.localStorageService.setToken(result.access_token!, result.refresh_token!, result.expires_in!);
                                    this.isTokenRefreshing!.next(true);
                                    this.isTokenRefreshing = undefined;
                                    console.log('Chiamata handlerequest 2');
                                    return this.handleRequest(request.clone({
                                        setHeaders: {
                                            Authorization: 'Bearer ' + result.access_token,
                                        },
                                    }), next);
                                }),
                                catchError(tokenError => {
                                    if (tokenError.url.includes('token')) {
                                        this.isTokenRefreshing!.next(false);
                                        this.isTokenRefreshing = undefined;
                                        this.fuseConfirmationService.openSnackBar({
                                            message: this.errorRefresh,
                                            type: SnackbarTypes.Error
                                        });
                                        this.localStorageService.cleanToken();
                                        this.router.navigateByUrl('/' + PathEnum.SIGN_IN);
                                        return of(null);
                                    } else {
                                        return throwError(() => error);
                                    }
                                }));
                        }
                    } else if (error?.status === 403 && error?.error?.code === 1009) {
                        this.fuseConfirmationService.openSnackBar({
                            message:  error?.error?.message,
                            type: SnackbarTypes.Error
                        });
                        this.router.navigateByUrl('/' + PathEnum.HOME);
                        return of(null);
                    } else {
                        return throwError(() => error);
                    }
                }));
    }
}
