import {animate, AUTO_STYLE, state, style, transition, trigger} from "@angular/animations";

export const inOutAnimation = trigger(
  'inOutAnimation',

  [

    state('in', style({opacity: 1})),

    transition(':enter', [
      style({opacity: 0}),
      animate(400)
    ]),

    transition(':leave',
      animate(400, style({opacity: 0})))
  ]
)

export const inAnimation = trigger(
  'inAnimation',

  [

    state('in', style({opacity: 1})),

    transition(':enter', [
      style({opacity: 0}),
      animate(400)
    ]),

    transition(':leave',
      animate(0, style({opacity: 0})))
  ]
)
export const collapseAnimation = trigger('collapseAnimation', [
  state(
    'false',
    style({
      height: 0
    })
  ),
  state(
    'true',
    style({
      height: AUTO_STYLE
    })
  ),
  transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
])

