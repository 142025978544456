import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {Navigation} from 'app/core/navigation/navigation.types';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {PathEnum} from "../../../../app.routing";
import {versionInfo} from "../../../../../../version-info";
import {
    DialogPrivacyComponent,
    DialogPrivacyI
} from "../../../../shared/dialogs/dialog-privacy/dialog-privacy.component";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {MatDialog} from "@angular/material/dialog";


@Component({
    selector: 'material-layout',
    templateUrl: './material.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MaterialLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    pathEnum = PathEnum;
    versionInfo = versionInfo;
    tag: string | undefined;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private dialog: MatDialog
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        console.log(versionInfo, 'versionInfo')
        this.tag = versionInfo?.tag?.split('-')[0];
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openDialogPrivacyTerminiECondizioni(isPrivacy: boolean) {

        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: DialogPrivacyI = {
            isPrivacy: isPrivacy,
            title: get(translation, isPrivacy ? 'privacy_and_condition.privacy' : 'privacy_and_condition.terms', null),
            icon: {
                name: 'mat_solid:policy',
                color: 'warning'
            }
        };
        this.dialog.open(DialogPrivacyComponent, {
            data,
            panelClass: 'dialog-responsive',
        });
    }
}
