import {Injectable} from '@angular/core';
import {AuthorityType, User} from '../../../api-clients/generated/services';
import jwt_decode from "jwt-decode";
import {Lang} from "../../layout/common/languages/languages.component";
import {StudentDetailService} from "../../modules/admin/students-list/student-detail/student-detail.service";
import {SnackbarTypes} from "../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {PathEnum} from "../../app.routing";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";

export interface TokenI {
    sub?: string;
    roles?: RolesEnum[];
    iss?: string;
    exp?: number;
    iat?: number;
}

export enum RolesEnum {
    ROLE_admin = "ROLE_admin",
    ROLE_docente = "ROLE_docente",
    ROLE_studente = "ROLE_studente",
    ROLE_personale_pta = "ROLE_personale_pta",
    ROLE_esterno = "ROLE_esterno"
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    lang = Object.values(Lang)
    authorityType = Object.values(AuthorityType).map(ruolo => 'ROLE_' + ruolo.toLowerCase());
    private errorRefresh: string;

    constructor(private studentDetailService: StudentDetailService,
                private router: Router,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService) {
        this.translocoService.selectTranslate('common.error_refresh_token').subscribe(value => this.errorRefresh = value)

    }

    setActiveLang(activeLang: string) {
        localStorage.setItem(StorageProperties.ACTIVELANG, activeLang)
    }

    getActiveLang(): string {
        let ln = localStorage.getItem(StorageProperties.ACTIVELANG) as Lang
        return ln && this.lang.includes(ln) ? localStorage.getItem(StorageProperties.ACTIVELANG) : Lang.it
    }

    decodeToken(): TokenI {
        const token = this.getAccessToken()
        if (token) {
            return jwt_decode(token)
        }
        return null
    }

    getRules() {
        const ruoli = this.decodeToken()?.roles
        if (!this.authorityType.some(ruolo => ruoli?.includes(<RolesEnum>ruolo))) {
            this.fuseConfirmationService.openSnackBar({
                message: this.errorRefresh,
                type: SnackbarTypes.Error
            });
            this.cleanToken();
            this.router.navigateByUrl('/' + PathEnum.SIGN_IN);
        }
        return ruoli
    }

    setToken(access_token: string, refresh_token: string, expiration: number) {
        localStorage.setItem(StorageProperties.ACCESS_TOKEN, access_token);
        localStorage.setItem(StorageProperties.REFRESH_TOKEN, refresh_token);
        localStorage.setItem(StorageProperties.EXPIRATION, JSON.stringify(expiration));
    }

    getRefreshToken() {
        return localStorage?.getItem(StorageProperties.REFRESH_TOKEN) || '';
    }

    getAccessToken(): string {
        return localStorage?.getItem(StorageProperties.ACCESS_TOKEN) || '';
    }

    cleanToken() {
        localStorage.removeItem(StorageProperties.ACCESS_TOKEN,);
        localStorage.removeItem(StorageProperties.REFRESH_TOKEN);
        localStorage.removeItem(StorageProperties.EXPIRATION);
        localStorage.removeItem(StorageProperties.USER);
        this.studentDetailService.ruoli = [];
    }

    getUser(): string {
        return localStorage?.getItem(StorageProperties.USER) || '';
    }

    getEmail(): string {
        return (JSON.parse(this.getUser()) as User)?.username;
    }

    getId(): string {
        return (JSON.parse(this.getUser()) as User)?.id;
    }

    setUser(user: string) {
        localStorage.setItem(StorageProperties.USER, user);
    }

    saveUser(user: User) {
        localStorage.setItem(StorageProperties.USER, JSON.stringify(user));
    }
}

export enum StorageProperties {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    EXPIRATION = 'EXPIRATION',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    PARTITA = 'PARTITA',
    USER = 'USER',
    ACTIVELANG = 'ACTIVELANG'
}


